<template>
  <div class="wrapper-certificatePageOne">
    <Breadcrumbs main="МОИ СЕРТИФИКАТЫ" title="ДОКУМЕНТЫ"/>
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 class="mb-0">КАК ПОЛУЧИТЬ <span class="text-card-header">АКЦИИ</span></h5>
      </div>
      <div class="card-header-description">
        Скачайте размещённые ниже документы и в точности выполните все шаги, которые описаны в прилагаемой к ним инструкции. А также оплатите сбор на сопутствующие расходы на сумму 1000 руб. (мы обязательно подготовим для вас специальный бонус для компенсации этих затрат)
      </div>
      <div class="card-mini-header">
        Если у вас возникнут вопросы, вы можете их задать в <span class="text-card-header">специальном закрытом чате для акционеров</span>
      </div>
    </div>
    <div class="col-12 mb-5 mt-5">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h5 class="mb-0">МОИ СЕРТИФИКАТЫ</h5>
          <div class="btn-wrapper-certificates">
          </div>
        </div>
        <div class="row p-2">
          <div class="documents d-flex justify-content-between" v-for="item in documents" :key="item.id">
            <h5 class="card-header card-item-name-document">
              {{ item.name }}
            </h5>
            <a class="card-item-download-document d-flex" :href="item.url" download>
              <img src="@/assets/images/certificate/download.svg" alt="">
              <div class="text-card-header">Скачать</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import codeConfirmation from "@/components/codeConfirmation.vue";

export default {
  name: "certificatePageTwo",
  components: {codeConfirmation},
  data(){
    return{
      isAmountAccount: false,
      isCode: false,
      idSystemPayment: 0,
      validate: {
        id: Number,
        code: '',
      },
      paymentData: {
        name: 'Оплата сбора',
        summa: 1000,
        typePayment: 6,
        isAmountAccount: false
      },
      documents:[
        {
          id: 1,
          name: "Инструкция",
          nameFile: "",
          url: "",
        },
        {
          id: 2,
          name: "Договор купли-продажи",
          nameFile: "",
          url: "/my/files/certificate/ДОГОВОР_КУПЛИ-ПРОДАЖИ _АКЦИЙ.doc",
        },
        {
          id: 3,
          name: "Согласие на обработку персональных данных",
          nameFile: "",
          url: "/my/files/certificate/Согласие_на_обработку_персональных_данных.doc",
        },
        {
          id: 4,
          name: "Распоряжение ВТБ-регистратору",
          nameFile: "",
          url: "/my/files/certificate/Акционеру_формы_документов_19_форма_Распоряжение_о_проведении.doc",
        },
      ],

    }
  },
  mounted(){
  },
  methods: {
  }
}
</script>


<style scoped>
.documents{
  align-items: center;
  padding-right: 30px;
  border-bottom: 1px solid #EFEFEF
}
.card-item-download-document{
  gap: 10px;
  cursor: pointer;
}
.card{
  margin: 0 auto;
  max-width: 1020px;
  width: 100%;
}

.card-mini-header{
  font-weight: 400;
  font-size: 14px;
  line-height: 23.8px;
  letter-spacing: 0.7px;
  padding: 0 20px 20px 20px;
}

.card-header-description{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #52526CBF;
  padding: 20px;
}

.text-card-header{
  color: #7366FF;
}
@media screen and (max-width: 580px){
  .card-header{
    flex-direction: column;
  }
  .btn-wrapper-certificates{
    margin-top: 10px;
  }
}
</style>