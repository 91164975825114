<template>
  <div class="col-xl-8 wrapper-form">
    <form class="card change-profile-wrapper" @submit.prevent="updateFormProfile">
      <div class="card-header">
        <h4 class="card-title mb-0">МОЙ ПРОФИЛЬ</h4>
        <div class="card-options">
          <a
            class="card-options-collapse"
            href="javascript:void(0)"
            data-bs-toggle="card-collapse"
            ><i class="fe fe-chevron-up"></i></a
          ><a
            class="card-options-remove"
            href="javascript:void(0)"
            data-bs-toggle="card-remove"
            ><i class="fe fe-x"></i
          ></a>
        </div>
      </div>
      <div class="alert-wrapper">
        <transition name="fade">
          <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
        </transition>
        <transition name="fade">
          <div v-if="errorMessage" class="alert alert-warning">{{ errorMessage }}</div>
        </transition>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="alert">
            поля, помеченные <span class="red">*</span>, являются обязательными к заполнению
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Имя <span class="red">*</span> </label>
              <input
                  :disabled="statusVerification !== null && statusVerification !== 2"
                class="form-control"
                v-model="form.firstName"
                type="text"
                placeholder="Ваше Имя"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Фамилия <span class="red">*</span></label>
              <input
                  :disabled="statusVerification !== null && statusVerification !== 2"
                class="form-control"
                v-model="form.lastName"
                type="text"
                placeholder="Ваша Фамилия"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Отчество</label>
              <input
                  :disabled="statusVerification !== null && statusVerification !== 2"
                class="form-control"
                v-model="form.middleName"
                type="text"
                placeholder="Ваше Отчество"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Telegram</label>
              <input
                class="form-control"
                v-model="form.telegram"
                type="text"
                placeholder="@nick"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Дата рождения <span class="red">*</span></label>
              <input
                  :disabled="statusVerification !== null && statusVerification !== 2"
                class="form-control"
                v-model="form.dateBirthday"
                type="date"
                placeholder="Дата рождения"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Пол <span class="red">*</span></label>
              <select
                  :disabled="statusVerification !== null && statusVerification !== 2"
                class="form-control btn-square"
                type="number"
                v-model="form.gender"
              >
                <option value="0" selected>--Выбрать--</option>
                <option value="1">--Мужской--</option>
                <option value="2">--Женский--</option>
              </select>
            </div>
          </div>


          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Номер телефона <span class="red">*</span></label>
              <input
                class="form-control"
                v-model="form.phone"
                v-mask="'+0 000 000 0000'"
                type="text"
                placeholder="Ваш телефон"
              />
              <small>Формат +7 000 000 0000</small>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-between align-items-center mb-2">
            <h5 class="m-0">Паспортные данные</h5>
            <div class="show-eyes cursor-pointer" @click.prevent="show">
              Скрыть данные
              <img v-if="active" class="svg-icon" src="@/assets/svg/eye-close.svg"  alt="">
              <img v-else class="svg-icon" src="@/assets/svg/eye-open.svg" alt="">
            </div>
          </div>
          <div class="col-12 position-relative">
            <div :class="{ 'active': active }" class="hidden-block d-flex justify-content-center align-items-center">
              <div class="show-description d-flex justify-content-center align-items-center" @click.prevent="show">
                <div class="svg-icon d-flex justify-content-center align-items-center me-2" v-html="svgEye"></div>
                Данные скрыты
              </div>
            </div>
            <div class="d-flex ms-3 mb-3 mt-2 justify-content-start flex-column flex-md-row">
              <div class="radio ms-0">
                <input id="radio-1" v-model="selectedOption" :value="1" name="radio" type="radio">
                <label for="radio-1" class="radio-labels">Являюсь жителем РФ</label>
              </div>

              <div class="radio ms-0 ms-md-4">
                <input id="radio-2" v-model="selectedOption" :value="2" name="radio" type="radio">
                <label  for="radio-2" class="radio-labels">Не являюсь жителем РФ</label>
              </div>

            </div>
            <div :class="{'active' : selectedOption === 1}" class="row container-passport">
              <div class="col-sm-12 col-md-12">
                <div class="mb-3 d-flex justify-content-between justify-content-md-start">
                  <label class="form-label d-flex align-items-center">Адрес регистрации в РФ</label>
                  <div class="cursor-pointer d-flex align-items-center ms-3" @click="switchModal">
                    {{ formWithRussianPassport.passportAddress ? 'Изменить адрес' : 'Указать адрес' }}
                  </div>
                </div>
              </div>
<!--              <div class="col-sm-12 col-md-12">-->
<!--                <div class="mb-3 d-flex flex-column flex-md-row">-->
<!--                  <div class="d-flex justify-content-between justify-content-md-start mb-2">-->
<!--                    <label class="form-label d-flex justify-content-center align-items-center">Адрес места жительства в РФ</label>-->
<!--                    <div class="cursor-pointer form-label d-flex justify-content-center align-items-center ms-3" @click="switchModal">-->
<!--                    Указать адрес-->
<!--                  </div>-->
<!--                  </div>-->
<!--                  <div class="checkbox ms-0 ms-md-3">-->
<!--                    <input id="checkbox1" type="checkbox" v-model="isPolitics"/>-->
<!--                    <label class="ms-3" for="checkbox1">-->
<!--                      Совпадает с адресом регистрации-->
<!--                    </label>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <div class="col-sm-6 col-md-6">
                <div class="mb-3">
                  <label class="form-label">Серия и номер <span class="red">*</span></label>
                  <input
                      :disabled="statusVerification !== null && statusVerification !== 2"
                      class="form-control"
                      v-model="formWithRussianPassport.passportNumber"
                      v-mask="'0000-000000'"
                      type="text"
                      placeholder="Серия и номер"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-md-6">
                <div class="mb-3">
                  <label class="form-label">Дата выдачи <span class="red">*</span></label>
                  <input
                      :disabled="statusVerification !== null && statusVerification !== 2"
                      class="form-control"
                      v-model="formWithRussianPassport.passportDate"
                      type="Date"
                      placeholder="@Дата выдачи"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-md-6">
                <div class="mb-3">
                  <label class="form-label">Код подразделения <span class="red">*</span></label>
                  <input
                      :disabled="statusVerification !== null && statusVerification !== 2"
                      class="form-control"
                      v-model="formWithRussianPassport.passportCode"
                      v-mask="'000-000'"
                      type="text"
                      placeholder="Код подразделения"
                  />
                </div>
              </div>

              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Кем выдан <span class="red">*</span></label>
                  <input
                      :disabled="statusVerification !== null && statusVerification !== 2"
                      class="form-control"
                      v-model="formWithRussianPassport.passportIssued"
                      type="text"
                      placeholder="Кем выдан?"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Адрес регистрации <span class="red">*</span></label>
                  <input
                      disabled
                      class="form-control"
                      :value="formWithRussianPassport.passportAddress"
                      type="text"

                      placeholder="Адрес регистрации"
                  />
                  <small
                  >Просьба вводить в формате: 000000, г. Санкт-Петербург, ул.
                    Строителей, д. 3 кв. 12</small
                  >
                </div>
              </div>
              <div class="col-sm-6 col-md-6">
                <div class="mb-3">
                  <label class="form-label">ИНН</label>
                  <input
                      class="form-control"
                      v-model="formWithRussianPassport.inn"
                      type="text"
                      placeholder="ИНН"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-md-6">
                <div class="mb-3">
                  <label class="form-label">СНИЛС</label>
                  <input
                      class="form-control"
                      v-model="formWithRussianPassport.snils"
                      type="text"
                      placeholder="СНИЛС"
                  />
                </div>
              </div>
            </div>
            <div :class="{'active' : selectedOption === 2}" class="row container-passport">
              <div class="col-sm-6 col-md-6">
                <div class="mb-3">
                  <label class="form-label">Серия и номер <span class="red">*</span></label>
                  <input
                      :disabled="statusVerification !== null && statusVerification !== 2"
                      class="form-control"
                      v-model="formWithoutRussianPassport.passportNumber"
                      v-mask="'0000-000000'"
                      type="text"
                      placeholder="Серия и номер"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-md-6">
                <div class="mb-3">
                  <label class="form-label">Дата выдачи <span class="red">*</span></label>
                  <input
                      :disabled="statusVerification !== null && statusVerification !== 2"
                      class="form-control"
                      v-model="formWithoutRussianPassport.passportDate"
                      type="Date"
                      placeholder="@Дата выдачи"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Кем выдан <span class="red">*</span></label>
                  <input
                      :disabled="statusVerification !== null && statusVerification !== 2"
                      class="form-control"
                      v-model="formWithoutRussianPassport.passportIssued"
                      type="text"
                      placeholder="Кем выдан?"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <a href="https://rentier.world/privacy-policy.pdf">Политика конфиденциальности</a>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button class="btn btn-primary" style="margin-top: 10px;" type="submit">Обновить данные</button>
      </div>
    </form>
    <div
      class="modal fade"
      :class="classError"
      id="exampleModalToggle"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel"
      tabindex="-1"
      ref="modal"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalToggleLabel">
              ПРЕДУПРЕЖДЕНИЕ!
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Закрыть"
            ></button>
          </div>
          <div class="modal-body">
            <p>Если вы измените данные, то вам снова нужно пройти верификацию по паспортным данным.</p>
            <p>Вы уверены, что хотите сбросить верификацию по паспортным данным?</p>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div
                class="col-sm-12 col-md-6 col-xl-6 d-flex justify-content-around aling-items-center mt-3"
              >
                <div class="btn btn-danger" data-bs-dismiss="modal" aria-label="Закрыть" @click="deleteAppeal(form.passportVerification.id)">Сбросить</div>
              </div>
              <div
                class="col-sm-12 col-md-6 col-xl-6 d-flex justify-content-center aling-items-center mt-3"
              >
                <button class="btn btn-light" data-bs-dismiss="modal"
              aria-label="Закрыть">
                  Отмена
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="isActiveModal ? `active` : ``" class="modal-wrapper">
      <div class="modal-overlay" @click="switchModal"></div>
      <div class="modal-content" >
        <div class="modal-message">
          <div class="modal-message-title modal-header d-flex justify-content-between align-items-center">
            <h3 class="modal-title-text m-0">Адрес регистрации в РФ</h3>
            <vue-feather @click="switchModal" type="x" class="cursor-pointer" ></vue-feather>
          </div>
        </div>
        <div class="modal-send-message">
          <div class="modal-send-message-input d-flex flex-column justify-content-center aling-items-center px-5 py-3" >
            <div class="w-100">
              <div class="mb-3">
                <label class="form-label">Город или населенный пункт</label>
                <input
                    :disabled="statusVerification !== null && statusVerification !== 2"
                    class="form-control"
                    v-model="address.city"
                    type="text"
                    placeholder="Город или населенный пункт"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Улица</label>
                <input
                    :disabled="statusVerification !== null && statusVerification !== 2"
                    class="form-control"
                    v-model="address.street"
                    type="text"
                    placeholder="Улица"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Дом</label>
                <input
                    :disabled="statusVerification !== null && statusVerification !== 2"
                    class="form-control"
                    v-model="address.ownership"
                    type="text"
                    placeholder="Дом"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Корпус</label>
                <input
                    :disabled="statusVerification !== null && statusVerification !== 2"
                    class="form-control"
                    v-mask="'00000000'"
                    v-model="address.corpus"
                    type="text"
                    placeholder="Корпус"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Квартира</label>
                <input
                    :disabled="statusVerification !== null && statusVerification !== 2"
                    class="form-control"
                    v-mask="'00000000'"
                    v-model="address.apartment"
                    type="text"
                    placeholder="Квартира"
                />
              </div>
              <div class="mb-2">
                <label class="form-label">Индекс</label>
                <input
                    :disabled="statusVerification !== null && statusVerification !== 2"
                    class="form-control"
                    v-mask="'000000'"
                    v-model="address.index"
                    type="text"
                    placeholder="Индекс"
                />
              </div>
            </div>
          </div>
          <div class=" py-3 modal-footer d-flex justify-content-end">
            <div class="btn btn-danger me-3" @click="switchModal">
              Отмена
            </div>
            <div class="btn btn-primary" @click="saveAddress">
              Сохранить
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import UserDataService from "@/services/UserDataService";
import VerificarionsDataService from "@/services/VerificarionsDataService"
import {reactive} from "vue";

export default {
  name: "editProfile",
  data() {
    return {
      active: true,
      errorMessage: "",
      selectedOption: 1,
      successMessage: "",
      statusVerification: null,
      showPopover: false,
      isActiveModal: false,
      svgEye: `
      <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5" stroke="#52526CBF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
      address: {
        city: "",
        street: "",
        ownership: "",
        corpus: "",
        apartment: "",
        index: ""
      },
      formWithoutRussianPassport: {
        passportNumber: "",
        passportDate: "",
        passportIssued: "",
      },
      formWithRussianPassport: {
        passportNumber: "",
        passportDate: "",
        passportIssued: "",
        passportAddress: "",
        passportCode: "",
        inn: "",
        snils: "",
      },
        form: {
          firstName: "",
          lastName: "",
          middleName: "",
          rank: "",
          telegram: "",
          dateBirthday: "",
          gender: 0,
          phone: "",
          isRussiansPassport: true,
          passportNumber: "",
          passportDate: "",
          passportIssued: "",
          passportAddress: "",
          passportCode: "",
          inn: "",
          snils: "",

        passportVerification: {
          id: null,
          comment: "",
          status: {
            id: null,
            name: "",
            engName: "",
          },
        },
      },
    };
  },
  methods: {
    show() {
      this.active = !this.active;
    },

    saveAddress(){
      let addressParts = [];

      if (this.address.index) {
        addressParts.push(`${this.address.index}`);
      }
      if (this.address.city) {
        addressParts.push(`г. ${this.address.city}`);
      }
      if (this.address.street) {
        addressParts.push(`ул. ${this.address.street}`);
      }
      if (this.address.ownership) {
        addressParts.push(`д. ${this.address.ownership}`);
      }
      if (this.address.corpus) {
        addressParts.push(`корпус. ${this.address.corpus}`);
      }
      if (this.address.apartment) {
        addressParts.push(`кв. ${this.address.apartment}`);
      }

      this.formWithRussianPassport.passportAddress = addressParts.join(', ');


      console.log(this.form.passportAddress)
      this.switchModal()
    },
    switchModal() {
      this.isActiveModal = !this.isActiveModal
    },
    getUser() {
      UserDataService.get()
        .then((response) => {
          if (response.data.isRussiansPassport) {

            this.selectedOption = 1

            this.form = response.data;

            const {
              passportNumber,
              passportDate,
              passportIssued,
              passportAddress,
              passportCode,
              inn,
              snils,
            } = this.form;

            this.formWithRussianPassport = {
              passportNumber,
              passportDate,
              passportIssued,
              passportAddress,
              passportCode,
              inn,
              snils,
            }
          } else {

            this.selectedOption = 2

            this.form = response.data;

            const {
              passportNumber,
              passportDate,
              passportIssued
            } = this.form;

            this.formWithoutRussianPassport = {
              passportNumber,
              passportDate,
              passportIssued
            }
          }
          this.statusVerification =
            response.data.passportVerification.status.id;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateFormProfile(){
      if (this.statusVerification !== null && this.statusVerification !== 2) {

        const { telegram, phone, inn, snils } = this.form

        const form = { telegram, phone, inn, snils };

        this.updateProfile(form)

      } else {
        switch (this.selectedOption) {
          case 1:
            this.form = {
              ...this.form,
              ...this.formWithRussianPassport,
            };
            this.updateProfile(this.form);
            break;
          case 2:
            this.form = {
              ...this.form,
              ...this.formWithoutRussianPassport,
            };
            this.updateProfileWithoutRussianPassport(this.form);
            break;
          default:
            this.errorMessage = "Ошибка обновления данных.";
            setTimeout(() => this.errorMessage = '', 3000);
            break;
        }
      }
    },
    updateProfile(form) {
      UserDataService.updateProfile(form)
        .then((response) => {
          this.successMessage = "Данные успешно обновлены.";
          setTimeout(() => this.successMessage = '', 3000);
        })
        .catch((e) => {
          this.errorMessage = "Ошибка обновления данных. " + e.response.data.message;
          setTimeout(() => this.errorMessage = '', 3000);
          console.log(e);
        });
    },
    updateProfileWithoutRussianPassport(form) {

      // const form = reactive({
      //   firstName: this.form.firstName,
      //   lastName: this.form.lastName,
      //   middleName: this.form.middleName,
      //   telegram: this.form.telegram,
      //   dateBirthday: this.form.dateBirthday,
      //   gender: this.form.gender,
      //   phone: this.form.phone,
      //   inn: this.form.inn,
      //   snils: this.form.snils,
      //   passportNumber: this.formWithoutRussianPassport.passportNumber,
      //   passportDate: this.formWithoutRussianPassport.passportDate,
      //   passportIssued: this.formWithoutRussianPassport.passportIssued,
      //   avatar: "undefined",
      //   country: "undefined"
      // })
      //
      // console.log(form)

      UserDataService.updateProfileWithoutRussianPassport(form)
          .then((response) => {
            this.successMessage = "Данные успешно обновлены.";
            setTimeout(() => this.successMessage = '', 3000); // Убираем сообщение через 3 секунды
          })
          .catch((e) => {
            if (e.response.data.message[0] === 'passportDate must be a Date instance') {
              this.errorMessage = "Ошибка обновления данных. Дата выдачи не должна быть пустой!";
              setTimeout(() => this.errorMessage = '', 3000); // Убираем сообщение через 3 секунды
            } else {
              this.errorMessage = "Ошибка обновления данных. " + e.response.data.message;
              setTimeout(() => this.errorMessage = '', 3000); // Убираем сообщение через 3 секунды
            }

            console.log(e);
          });
    },

    deleteAppeal(id) {
      VerificarionsDataService.deleteAppeal(id)
      .then(({ data }) => {
          this.messageSuccess = data.message;
          location.reload();
        })
        .catch((e) => {
          console.log(e);
          this.messageError = e.message;
        });
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>
<style scoped>
.popover-container {
  position: relative;
  display: inline-block;
  min-width: 30px;
}

.popover {
  position: absolute;
  top: 0%;
  left: 0px;
  transform: translate(-110%, -50%);
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 9999;
  min-width: 200px;
}
.red{
  color: var(--theme-secondary);
}
.alert-wrapper{
  position: fixed;
  right: 0;
  top: 150px;
  z-index: 999;
  translate: all .3;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
