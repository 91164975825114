<template>
  <div class="userService_wrapper">
    <Breadcrumbs title="ПЕРЕДАТЬ КЛИЕНТА"/>

    <p class="alert alert-warning modal-windows" v-if="errorMessage">{{ errorMessage }}</p>
    <p class="alert alert-success modal-windows" v-if="successMessage">{{ successMessage }}</p>
    <div class=" card form-send shadow-lg">
      <div class="row d-flex flex-column flex-lg-row p-md-5 p-3">
        <form class="col-12 col-md-12 col-lg-5 col-lx-5 order-2 order-lg-1" @submit.prevent="postSendServices">
          <div class="">
            <h4 class="card-title mb-3">Передать клиента</h4>
          </div>
          <div>
            <div class="row">
              <div class="col-12 mb-3">
                <h5 class="m-0">
                  Выберите тип клиента, которому вы хотите предложить рекомендации
                </h5>
                <div class="wrapper-switch d-flex position-relative">
                  <input type="radio" class="btn-check" v-model="formData.category" :value="1" name="options-base" id="option1" autocomplete="off" checked>
                  <label class="btn-switch m-0 p-2 cursor-pointer" for="option1">
                    <i class="fa fa-bank"></i>
                    Юрист
                  </label>

                  <input type="radio" class="btn-check" v-model="formData.category" :value="2" name="options-base" id="option2" autocomplete="off">
                  <label class="btn-switch m-0 p-2 cursor-pointer" for="option2">
                    <i class="fa fa-briefcase"></i>
                    Бухгалтер
                  </label>

                  <input type="radio" class="btn-check" v-model="formData.category" :value="3" name="options-base" id="option3" autocomplete="off">
                  <label class="btn-switch m-0 p-2 cursor-pointer" for="option3">
                    <i class="fa fa-gears"></i>
                    IT-технологии
                  </label>
                </div>
              </div>
              <div class="col-sm-7 col-md-7">
                <div class="mb-3">
                  <label class="form-label">Фамилия</label>
                  <input
                      required
                      v-model="formData.lastName"
                      class="form-control"
                      type="text"
                      placeholder="Фамилия"
                      :class="{ 'is-invalid': !formData.lastName && isTouched1 }"
                      @input="isTouched1 = true"
                  />
                  <div
                      v-if="!formData.lastName && isTouched1"
                      class="invalid-feedback"
                  >
                    Поле должно быть заполнено
                  </div>
                </div>
              </div>
              <div class="col-sm-5 col-md-5">
                <div class="mb-3">
                  <label class="form-label">Имя</label>
                  <input
                      required

                      v-model="formData.firstName"
                      class="form-control"
                      type="text"
                      placeholder="Имя"
                      :class="{ 'is-invalid': !formData.firstName && isTouched2 }"
                      @input="isTouched2 = true"
                  />
                  <div
                      v-if="!formData.firstName && isTouched2"
                      class="invalid-feedback"
                  >
                    Поле должно быть заполнено
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Отчество</label>
                  <input
                      required
                      v-model="formData.middleName"
                      class="form-control"
                      type="text"
                      placeholder="Отчество"
                      :class="{ 'is-invalid': !formData.middleName && isTouched3 }"
                      @input="isTouched3 = true"
                  />
                  <div
                      v-if="!formData.middleName && isTouched3"
                      class="invalid-feedback"
                  >
                    Поле должно быть заполнено
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="mb-3">
                  <!-- <label class="form-label">Номер телефона</label>
                  <input class="form-control"
                        required
                        v-model="formData.phone"
                        type="text" :placeholder="servicesId.phone"
                        :class="{ 'is-invalid': !formData.phone && isTouched4 }"
                        @input="isTouched4 = true"/>
                  <small>Формат +7 000 000 0000</small>
                  <div
                      v-if="!formData.phone && isTouched4"
                      class="invalid-feedback"
                  >
                    Поле должно быть заполнено
                  </div> -->
                  <div class="mb-3">
                    <label class="form-label">Телефон</label>
                    <input
                        required
                        v-model="formData.phone"
                        v-mask="'+0 000 000 0000'"
                        class="form-control"
                        type="tel"
                        placeholder="Телефон"
                        :class="{ 'is-invalid': !formData.phone && isTouched4 }"
                        @input="isTouched4 = true"
                    />
                    <small>Формат +7 XXX XXX XX XX</small>
                    <div
                        v-if="!formData.phone && isTouched4"
                        class="invalid-feedback"
                    >
                      Поле должно быть заполнено
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div>
                  <label class="form-label">Описание</label>
                  <textarea
                      required
                      class="form-control"
                      rows="5"
                      placeholder="Описание"
                      v-model="formData.comment"
                      :class="{ 'is-invalid': !formData.comment && isTouched5 }"
                      @input="isTouched5 = true"
                  ></textarea>
                  <div
                      v-if="!formData.comment && isTouched5"
                      class="invalid-feedback"
                  >
                    Поле должно быть заполнено
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-outline-primary mt-3" type="submit">Отправить</button>
          </div>
        </form>
        <div class="col-12 col-md-12 col-lg-7 col-lx-7 read-more-wrapper order-1 order-lg-2">
          <div v-if="formData.category === 1">
            <div class="image d-flex justify-content-center align-items-center mb-3">
              <img src="@/assets/images/image_service.png">
            </div>
            <div class="description" :class="{ 'full': isCollapsed }">
              <p class="first-paragraph mb-3 text-content">
                Безусловно, постоянный количественный рост и сфера нашей активности создаёт предпосылки для позиций, занимаемых участниками в отношении поставленных задач. В частности, базовый вектор развития играет важную роль в формировании укрепления моральных ценностей. Современные технологии достигли такого уровня, что реализация намеченных плановых заданий говорит о возможностях существующих финансовых и административных условий. Разнообразный и богатый опыт говорит нам, что современная методология разработки требует анализа позиций, занимаемых участниками в отношении поставленных задач. Приятно, граждане, наблюдать, как элементы политического процесса ограничены исключительно образом мышления. Предварительные выводы неутешительны: существующая теория в значительной степени обусловливает важность как самодостаточных, так и внешне зависимых концептуальных решений.
              </p>
              <p class="second-paragraph text-content">
                Для современного мира высокое качество позиционных исследований представляет собой интересный эксперимент проверки модели развития. Предварительные выводы неутешительны: граница обучения кадров не даёт нам иного выбора, кроме определения дальнейших направлений развития. Принимая во внимание показатели успешности, сплочённость команды профессионалов напрямую зависит от экономической целесообразности принимаемых решений. Принимая во внимание показатели успешности, понимание сути ресурсосберегающих технологий создаёт предпосылки для распределения внутренних резервов и ресурсов.
              </p>
            </div>
            <div class="d-flex justify-content-center aling-items-center my-3">
              <button @click="toggleCollapse" class="btn btn-primary">
                {{ isCollapsed ? 'Скрыть' : 'Читать дальше' }}
              </button>
            </div>
          </div>
          <div v-if="formData.category === 2">
            <div class="image d-flex justify-content-center align-items-center mb-3">
              <img src="@/assets/images/image_accountant.png">
            </div>
            <div class="description" :class="{ 'full': isCollapsed }">
              <p class="first-paragraph mb-3 text-content">
                Безусловно, постоянный количественный рост и сфера нашей активности создаёт предпосылки для позиций, занимаемых участниками в отношении поставленных задач. В частности, базовый вектор развития играет важную роль в формировании укрепления моральных ценностей. Современные технологии достигли такого уровня, что реализация намеченных плановых заданий говорит о возможностях существующих финансовых и административных условий. Разнообразный и богатый опыт говорит нам, что современная методология разработки требует анализа позиций, занимаемых участниками в отношении поставленных задач. Приятно, граждане, наблюдать, как элементы политического процесса ограничены исключительно образом мышления. Предварительные выводы неутешительны: существующая теория в значительной степени обусловливает важность как самодостаточных, так и внешне зависимых концептуальных решений.
              </p>
              <p class="second-paragraph text-content">
                Для современного мира высокое качество позиционных исследований представляет собой интересный эксперимент проверки модели развития. Предварительные выводы неутешительны: граница обучения кадров не даёт нам иного выбора, кроме определения дальнейших направлений развития. Принимая во внимание показатели успешности, сплочённость команды профессионалов напрямую зависит от экономической целесообразности принимаемых решений. Принимая во внимание показатели успешности, понимание сути ресурсосберегающих технологий создаёт предпосылки для распределения внутренних резервов и ресурсов.
              </p>
            </div>
            <div class="d-flex justify-content-center aling-items-center my-3">
              <button @click="toggleCollapse" class="btn btn-primary">
                {{ isCollapsed ? 'Скрыть' : 'Читать дальше' }}
              </button>
            </div>
          </div>
          <div v-if="formData.category === 3">
            <div class="image d-flex justify-content-center align-items-center mb-3">
              <img src="@/assets/images/image_it.png">
            </div>
            <div class="description" :class="{ 'full': isCollapsed }">
              <p class="first-paragraph mb-3 text-content">
                Безусловно, постоянный количественный рост и сфера нашей активности создаёт предпосылки для позиций, занимаемых участниками в отношении поставленных задач. В частности, базовый вектор развития играет важную роль в формировании укрепления моральных ценностей. Современные технологии достигли такого уровня, что реализация намеченных плановых заданий говорит о возможностях существующих финансовых и административных условий. Разнообразный и богатый опыт говорит нам, что современная методология разработки требует анализа позиций, занимаемых участниками в отношении поставленных задач. Приятно, граждане, наблюдать, как элементы политического процесса ограничены исключительно образом мышления. Предварительные выводы неутешительны: существующая теория в значительной степени обусловливает важность как самодостаточных, так и внешне зависимых концептуальных решений.
              </p>
              <p class="second-paragraph text-content">
                Для современного мира высокое качество позиционных исследований представляет собой интересный эксперимент проверки модели развития. Предварительные выводы неутешительны: граница обучения кадров не даёт нам иного выбора, кроме определения дальнейших направлений развития. Принимая во внимание показатели успешности, сплочённость команды профессионалов напрямую зависит от экономической целесообразности принимаемых решений. Принимая во внимание показатели успешности, понимание сути ресурсосберегающих технологий создаёт предпосылки для распределения внутренних резервов и ресурсов.
              </p>
            </div>
            <div class="d-flex justify-content-center aling-items-center my-3">
              <button @click="toggleCollapse" class="btn btn-primary">
                {{ isCollapsed ? 'Скрыть' : 'Читать дальше' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper-table mt-5 col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title mb-0">Все заявки</h4>
          <div class="card-options">
            <a
                class="card-options-collapse"
                href="#"
                data-bs-toggle="card-collapse"
            ><i class="fe fe-chevron-up"></i></a
            ><a
              class="card-options-remove"
              href="#"
              data-bs-toggle="card-remove"
          ><i class="fe fe-x"></i
          ></a>
          </div>
        </div>
        <div class="table-responsive add-project">
          <table class="table card-table table-vcenter text-nowrap">
            <thead>
            <tr>
              <th>Имя</th>
              <th>Дата</th>
              <th>Статус</th>
              <th>Цена</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in servicesId" :key="index">
              <td>
                <tr>
                  {{ item.fio }}
                </tr>
              </td>
              <td>
                <tr>
                  {{ formattedDate(item.created_at) }}
                </tr>
              </td>
              <td>
                <tr>{{ item.status.name }}</tr>
              </td>
              <td>
                <tr>
                  <span v-if="item.price>0">
                  {{ item.price }}
                    </span>
                  <span v-else> - </span>
                </tr>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import ServiceITDataService from "@/services/ServiceITDataService";
import UserDataService from "@/services/UserDataService";
import moment from "moment";
import "moment/locale/ru";

export default {
  name: "userService",
  data() {
    return {
      errorMessage: '',
      successMessage: '',
      id: "",
      servicesId: [],
      isCollapsed: false,
      formData: {
        firstName: "",
        lastName: "",
        middleName: "",
        phone: "",
        category: parseInt(1),
        comment: "",
      },
    };
  },
  mounted() {
    this.getUser();
    this.getCurrent();
  },
  methods: {
    getUser() {
      UserDataService.get()
          .then(({data}) => {
            this.id = data.id;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    putItService() {
      ServiceITDataService.putItService()
    },
    getCurrent() {

      ServiceITDataService.getCurrent()
          .then(({data}) => {
            this.servicesId = data.recommendationStatements	;
          })
          .catch((e) => {
            console.log("ID ERROR", e);
          });
    },
    async postSendServices() {
      const requiredFields = document.querySelectorAll('input[required]');
      requiredFields.forEach(field => {
        if (!field.value) {
          // Если поле не заполнено, выделяем его красным
          field.classList.add('is-invalid');
        } else {
          // Если поле заполнено, удаляем класс is-invalid
          field.classList.remove('is-invalid');
        }
      });


      ServiceITDataService.postSendService(this.formData)
          .then(() => {
            this.successMessage = 'Заявление успешно добавленно.';
            this.getCurrent();
          })
          .catch((error) => {
            this.errorMessage = 'Пожалуйста, заполните все поля.';
            console.log(error);
          });

    },
    formattedDate(date) {
      moment.locale("ru");

      if (date === this.servicesId.created_at) {
        return moment(date).format("D MMMM HH:mm");
      } else {
        return moment(date).format("D MMMM HH:mm");
      }
    }
  },
};
</script>

<style scoped>
.wrapper-table {
  margin: 30px 10px 50px 5px;
}

.knowledgebase-page {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-top: 40px;
}

.userService_wrapper {
  display: flex;
  flex-direction: column;
}

.knowledgebase {
  display: flex;
  background: url(@/assets/images/knowledgebase/bg_1.jpg) center center/cover no-repeat;
}
.btn-primary{
  display: none;
}

@media (max-width: 768px) {
  .knowledgebase {
    display: flex;
    background: none;
  }

  .btn-primary{
    display: block;
  }
}

.form-send {
  margin: 20px auto;
}

.right_knowledgebase {
  height: 100%;
}

.user_form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-windows {
  border-radius: 10px;
}

.btn.btn-outline-primary:hover {
  background: var(--theme-deafult) !important;
  border-color: var(--theme-deafult) !important;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
