<template>
  <Breadcrumbs main="ПРОЕКТЫ" title="МОИ СЕРТИФИКАТЫ"/>
  <div class="col-12 mb-5">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 class="mb-0">МОИ СЕРТИФИКАТЫ</h5>
        <div class="btn-wrapper-certificates">
          <div class="btn btn btn-outline-primary" @click="openModal()">
            Обменять сертификаты
          </div>
          <router-link class="btn btn btn-primary" :to="{ name: 'buyCertificate' }">
            Докупить сертификат
          </router-link>
        </div>
      </div>
      <div class="row p-2">
        <div class="col-lg-4 col-md-6 " v-for="certificate of myCertificates">
          <div class="project-box">
            <span class="badge bg-success" v-if="certificate.status.id === 1">{{ certificate.status.name }}</span>
            <span class="badge bg-warning" v-if="certificate.status.id === 2">{{ certificate.status.name }}</span>
            <span class="badge bg-primary" v-if="certificate.status.id === 3">{{ certificate.status.name }}</span>
            <h6 class="mt-2">Сертификат Платформы "Рантье"</h6>
            <p>{{ certificate.certificateNumber }}</p>
            <div class="row details">
              <div class="col-6"><span>Количество </span></div>
              <div class="col-6 font-primary">{{ format(parseFloat(certificate.denominations).toFixed(3)) }}</div>
              <div class="col-6"><span>Цена сертификата на момент покупки</span></div>
              <div class="col-6 font-primary">{{ format(certificate.amount) }} &#8381;</div>
              <div class="col-6"><span>Текущая стоимость</span></div>
              <div class="col-6 font-primary">
                {{ format(formatTwo(certificate.denominations * certificatePrice).toFixed(3)) }} &#8381;
              </div>
              <div class="col-6"><span>Дата покупки</span></div>
              <div class="col-6 font-primary">{{ new Date(certificate.created_at).toLocaleDateString('ru-Ru') }}</div>
              <div class="col-6"><span>Опционное соглашение</span></div>
              <div class="col-6 font-primary">
                <button class="btn btn-primary" @click="getDogovor(certificate.id)">Скачать</button>
              </div>
              <div class="col-6" v-if="email==='test@test.test'"><span>Чек об операции</span></div>
              <div class="col-6 font-primary" v-if="email==='test@test.test'"><a href="https://rentier.world/test/1.pdf"
                                                                                 target="_blank">Посмотреть</a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <alert-error v-if="errorMessage" :text="errorMessage" class="top-right-alert"/>
    <alert-success v-if="successMessage" :text="successMessage" class="top-right-alert"/>
    <div class="card-body d-flex flex-lg-row justify-content-center align-items-center flex-column">
      <div class="wrapper-send">
        <h5>ПЕРЕДАТЬ СЕРТИФИКАТ</h5>
        <div class="row p-3">
          <p><b> Для успешного перевода Сертификата Стороны должны быть верифицированы в системе с заполнением паспортных
            данных. </b></p>

          <div class="col-12 col-md-9 mt-3">
            <input type="text" class="form-control" placeholder="Получатель, email" :disabled="isCodeTransfer"
                   v-model="transfer.email">
          </div>
          <div class="col-12 col-md-9 mt-3">
            <input type="Number" min="1" max="50000" class="form-control" placeholder="Количество"
                   :disabled="isCodeTransfer" v-model="transfer.count">
          </div>

          <div class="col-12 col-md-9 mt-3">
            <button class="btn btn-primary" type="button" @click="clickTransfer"
                    data-original-title="btn btn-link btn-lg"> Передать сертификаты
            </button>
          </div>

          <div class="form-inline row p-3 pb-0" v-if="isCodeTransfer">
            <div class="row">
              <div class="col-lg-6 col-sm-6">
                <input type="text" class="form-control mt-2" id="isCode" placeholder="Код" v-model="validateTransfer.code">
              </div>
              <button class="btn btn btn-primary col-lg-6 col-sm-6 mt-2 " type="button"
                      :disabled="this.isSendTransfer || this.validateTransfer.code.length < 3"
                      @click="codeValidateTransfer">
                Подтвердить операцию
              </button>
            </div>
            <p class="font-warning">Для подтверждения операции и подписания <a href="../documents/certificate-transfer"
                                                                               target="_blank">Дополнительное соглашения к
              опционному соглашению
            </a> введите код.
              Он направлен на Вашу почту.
            </p>
          </div>

        </div>

      </div>
      <div class="position-relative">
        <img src="@/assets/images/graph-dev.png" class="w-100" alt="">
        <div class="modal-dev px-5 py-5 d-flex justify-content-center align-items-center">
          <div v-html="svgCircle" class="me-2"></div>
          На стадии разработки
        </div>
      </div>
    </div>

    <transition name="modal-fade">
      <div v-if="isOpenModal" class="modal-overlay" @click.self="closeModal()">
          <modalCertificate
              @closeModal="closeModal"
          />
      </div>
    </transition>
  </div>

</template>

<script>
import CertificateDataService from "@/services/CertificateDataService";
import AlertSuccess from "@/components/alert-succes.vue";
import alertError from "@/components/alert-error.vue";
import UserDataService from "@/services/UserDataService";
import MoneyDataService from "@/services/MoneyDataService";
import OperationsDataService from "@/services/OperationsDataService";
import info_circle from "@/assets/svg/info-circle.svg"
import modalCertificate from "./certificateModal.vue"
import codeConfirmation from "@/components/codeConfirmation.vue"

export default {
  name: 'oneProject',
  data() {
    return {
      countCertificateAvailable: Number,
      certificatePrice: Number,
      idSystemPayment: 0,
      isAmountAccount: false,
      isButtonDisabled: false,
      progressBarWidth: '100%',
      email: '',
      certificateCount: 1,
      isSummaValid: false,
      isOpenModal: false,
      countPayment: Number,
      summaPayment: 1000,
      myCertificates: [],

      errorMessageOne: '',
      errorMessage: '',
      successMessageOne: '',
      successMessage: '',

      svgCircle: `
      <svg width="24" height="24" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.9997 69.6663C55.4163 69.6663 69.6663 55.4163 69.6663 37.9997C69.6663 20.583 55.4163 6.33301 37.9997 6.33301C20.583 6.33301 6.33301 20.583 6.33301 37.9997C6.33301 55.4163 20.583 69.6663 37.9997 69.6663Z" stroke="#52526CBF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M38 25.333V41.1663" stroke="#52526CBF" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M37.9824 50.667H38.0109" stroke="#52526CBF" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,

      paymentData: {
        name: 'Покупка сертификата',
        summa: 1000,
        typePayment: 3,
        isAmountAccount: false
      },

      isCode: false,
      validate: {
        id: Number,
        code: '',
      },

      isCodeTransfer: false,
      isSendTransfer: false,
      transfer: {
        count: Number,
        email: ''
      },
      validateTransfer: {
        id: Number,
        code: '',
      },

    }
  },
  components: {
    AlertSuccess,
    alertError,
    codeConfirmation,
    modalCertificate
  },
  mounted() {
    this.getMy();
  },
  methods: {
    openModal(){
      this.isOpenModal = true
    },
    closeModal(){
      this.isOpenModal = false
    },
    updateCertificateCount() {
      this.certificateCount = Math.floor(this.summaPayment / this.certificatePrice * 10000) / 10000;
      this.paymentData.summa = Math.ceil(this.certificateCount * this.certificatePrice);
      console.log(this.paymentData.summa);
    },
    updateSummaPayment() {
      console.log(this.summaPayment);
      this.summaPayment = Math.ceil(this.certificateCount * this.certificatePrice);
      this.paymentData.summa = this.summaPayment;
    },
    formatTwo: function (number) {
      return Math.floor(number * 100) / 100;
    },
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
    // short: num => 
    clickTransfer() {
      this.transfer.count = Number(this.transfer.count);
      if (this.transfer.count <= 0) {
        this.errorMessageOne = '';
        this.errorMessage = 'Укажите количество.'
        alertError(`Ввелите количество большо 0`)
        setTimeout(() => {
          this.errorMessage = '';
        }, 3500);
        return;
      }

      if (!this.transfer.email) {
        this.errorMessageOne = '';
        this.errorMessage = 'Укажите Email получателя.'
        setTimeout(() => {
          this.errorMessage = '';
        }, 3500);
        return;

      }

      this.isCodeTransfer = true;

      OperationsDataService
          .get({typeOperation: 6})
          .then(response => {
            this.validateTransfer.id = response.data;
          })

      UserDataService.getProfile(this.transfer.email)
          .then(response => {
            const profile = response.data;
            localStorage.setItem('userRecipient', JSON.stringify(profile));
          })

      UserDataService.get()
          .then(response => {
            const profile = response.data;
            localStorage.setItem('userSender', JSON.stringify(profile));
          })

    },
    codeValidateTransfer() {
      this.isSendTransfer = true;

      OperationsDataService
          .codeValidate(this.validateTransfer)
          .then(response => {
            const isValidate = response.data;
            if (isValidate) this.transferCertificate();
            else {
              this.errorMessage = 'Не верный код. Попробуйте ввести заново, или обновите страницу и сгенерируйте новый.'
              this.isSendTransfer = false;
            }
          })
    },
    transferCertificate() {
      CertificateDataService.transfer(this.transfer)
          .then(() => {
            this.successMessage = 'Перевод выполнен.'
            location.reload();
          })
          .catch(e => {
            this.errorMessage = e.response.data.message;
            console.log(e);
          });
    },

    clickPayment() {
      this.isButtonDisabled = true;

      if (this.summaPayment <= 0 || !this.summaPayment) {
        this.errorMessageOne = 'Укажите количество больше нуля.';
        this.errorMessage = '';

        setTimeout(() => {
          this.errorMessageOne = '';
        }, 3500);

        return;
      }

      this.isCode = true;
      OperationsDataService
          .get({typeOperation: 3})
          .then(response => {
            this.validate.id = response.data;
          })
    },
    codeValidate() {
      OperationsDataService
          .codeValidate(this.validate)
          .then(response => {
            const isValidate = response.data;
            if (isValidate) this.choiceAcquiring();
            else this.errorMessage = 'Не верный код. Попробуйте ввести заново, или обновите страницу и сгенерируйте новый.'
          })
    },
    choiceAcquiring() {
      localStorage.setItem('paymentData', JSON.stringify(this.paymentData));
      if (this.isAmountAccount) this.payment();
      if (this.idSystemPayment === 0 && !this.isAmountAccount) window.location.href = '/my/payments/cloudpayments';
      if (this.idSystemPayment === 1 && !this.isAmountAccount) this.payment();
    },

    getMy() {
      MoneyDataService.getIdPaymentService()
          .then(response => {
            this.idSystemPayment = Number(response.data.idSystemPayment);
            this.idSystemPayment = 1;
          })
          .catch(e => {
            console.log(e);
          });

      CertificateDataService.getMyCertificate()
          .then(response => {
            const certificate = response.data;
            this.myCertificates = certificate.myCertificates;

            this.countCertificateAvailable = certificate.countCertificateAvailable.value;
            this.certificatePrice = certificate.certificatePrice.value;
          })
          .catch(e => {
            console.log(e);
          });

      UserDataService.get()
          .then(response => {
            const user = response.data;
            this.email = user.user.email;
          }).catch(e => {
        console.log(e);
      });
    },

    checkSumma() {
      if (!this.summaPayment || this.summaPayment > 50000) {
        this.errorMessage = 'Укажите сумму сертификата от 500 до 50 000 рублей.'
        return false;
      }
      return true;
    },
    validateSumma() {
      this.isSummaValid = this.summaPayment >= 1000 && this.summaPayment <= this.countCertificateAvailable * this.certificatePrice;
    },
    payment() {
      if (!this.checkSumma()) return;
      MoneyDataService
          .payment({
            typePayment: 3,
            selectPaymentSystem: 1,
            isAmountAccount: this.isAmountAccount,
            summa: Number(this.summaPayment),
          })
          .then(response => {
            if (this.isAmountAccount)
              this.successMessage = 'Благодарим за покупку.'
            else {
              window.location.href = response.data.url;
              this.successMessage = 'Перейдите на сайт банка для оплаты ранга.'
            }
          })
          .catch(e => {
            this.errorMessage = 'Ошибка оплаты. ' + e.response.data.message;
          });
    },
    getDogovor(idCertificate) {
      CertificateDataService.paymentCertificateDogovor({idCertificate: idCertificate})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/pdf'});
            const downloadUrl = window.URL.createObjectURL(blob);
            // Создаем тег <a> для скачивания
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `rentier-esr-${idCertificate}.pdf`); // задаем имя файла для скачивания
            document.body.appendChild(link);
            link.click();

            // Очищаем ссылку на URL после скачивания
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
          })
          .catch(e => {
            console.log(e);
          });
    },
    checkBox() {
      if (this.isAmountAccount)
        localStorage.setItem('isAmountAccount', true);
      else
        localStorage.removeItem('isAmountAccount')
    },
    changeCount() {
      this.paymentData.summa = this.summaPayment;
    },
  }
}

</script>

<style scoped>
.top-right-alert {
  position: fixed;
  top: 60px;
  right: 20px;
  z-index: 9999;
}
.wrapper-send{
  width: clamp( 100px, 100% , 700px);
}
.modal-dev{
  position: absolute;
  border-radius: 18px;
  width: clamp( 100px, 100% , 700px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #59667A1A;
  backdrop-filter: blur(8px);
  color: #52526CBF;
}
.form-inline{
  margin: 0 auto;
}
.btn-wrapper-certificates{
  display: flex;
  gap: 16px;
}
.modal-overlay {
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-fade-enter-active {
  transition: opacity 0.3s ease;
}

.modal-fade-enter-from {
  opacity: 0;
}

.modal-fade-leave-active {
  transition: opacity 0.3s ease;
}

.modal-fade-leave-to {
  opacity: 0;
}
</style>