<template>
  <Breadcrumbs title="МОИ ФИНАНСЫ" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="pt-3 px-3">
            <h6>ПОДАРОЧНЫЙ СЕРТИФИКАТ</h6>
          </div>
          <hr>
          <div class="pb-3 px-3">
            <p class="text-dis">
              Пополняйте свой основной счет с помощью подарочного сертификата, чтобы одним нажатием кнопки оплачивать любые товары и услуги экосистемы «Рантье». А также помогать с оплатой своим партнерам.
            </p>
            <label class="surprise-text">
              Сумма сертификата
            </label>
            <div class="d-flex flex-column flex-md-row">
              <input
                  type="number"
                  min="1"
                  max="50000"
                  class="form-control surprise-input"
              />
              <div>
                <button
                    class="btn btn-primary ms-0 ms-md-3 mt-2 mt-md-0"
                    type="button"
                    @click="switchModal"
                    data-original-title="btn btn-link btn-lg"
                >
                  купить
                </button>
              </div>
            </div>
            <p class="text-disc pt-3">
              Нажимая "Купить", я акцептирую (подписываю)
              <span class="text-p cursor-pointer">
                Договор купли-продажи подарочного сертификата.
              </span>
            </p>
          </div>
        </div>
        <div class="card">
          <alert-error v-if="errorMessage" :text="errorMessage" class="top-right-alert"/>
          <alert-success v-if="successMessage" :text="successMessage" class="top-right-alert"/>

          <div class="row p-3">
            <h6>ПЕРЕВОД СРЕДСТВ</h6>
            <p>
              Перевод средств другому Пользователю доступен только c основного счёта. Для успешного перевода Вы должны быть верифицированы в системе* с заполнением паспортных данных.
            </p>
            <div class="col-sm-6 mt-1 col-lg-3">
              <input
                type="text"
                class="form-control"
                placeholder="Получатель, email"
                :disabled="isCode"
                v-model="userRecipientEmail"
              />
            </div>
            <div class="col-sm-6 mt-1 col-lg-3">
              <input
                type="Number"
                min="1"
                max="50000"
                class="form-control"
                placeholder="Сумма"
                :disabled="isCode"
                v-model="transferAmount"
              />
            </div>
            <div class="col-sm-6 mt-1 col-lg-3">
              <button
                class="btn btn-primary"
                type="button"
                @click="clickPayment"
                :disabled="isCode"
                data-original-title="btn btn-link btn-lg"
              >
                Перевести
              </button>
            </div>
            <div class="form-inline input-code-wrapper row p-3 pb-0" v-if="isCodeTransfer">
              <div class="row input-code">
                <div class="col-lg-3 col-sm-6">
                  <input
                      type="text"
                      class="form-control mt-2"
                      id="isCode"
                      placeholder="Код"
                      v-model="validate.code"
                  />
                </div>
                <button
                    class="btn mt-2 btn-primary col-lg-3 col-sm-6"
                    type="button"
                    :disabled="this.isSend || this.validate.code.length < 3"
                    @click="codeValidate"
                >
                  Подтвердить операцию
                </button>
              </div>
              <p class="font-warning">
                Для подтверждения операции и подписания
                <a href="../documents/money-transfer-notification" target="_blank"
                >Уведомление об уступки прав</a
                >
                введите код. Он направлен на Вашу почту. Вы с получателем можете
                использовать этот договор для оформления своих правоотношений.
                <a href="../documents/money-transfer" target="_blank"
                >Образец Договора уступки прав.</a
                >
              </p>
            </div>
          </div>
          <hr />
          <div class="row p-3">
            <h6>ВЫВОД СРЕДСТВ</h6>
            <p>
              Вывод средств из основного счёта доступен при балансе от 30 000 ₽. 
            </p>
            <p>
              Для успешного вывода Вы должны быть верифицированы в системе* с заполнением паспортных данных.
            </p>
            <div class="col-sm-6 mt-1 col-lg-3">
              <input
                type="number"
                min="1"
                max="50000"
                class="form-control"
                placeholder="Сколько"
                :disabled="isCode"
                v-model.number="withdrawAmount"
              />
            </div>
            <div class="col-sm-6 mt-1 col-lg-3">
              <button
                class="btn btn-primary"
                type="button"
                @click="clickWithdraw"
                :disabled="isCode"
                data-original-title="btn btn-link btn-lg"
              >
                Вывести
              </button>
            </div>
          </div>

          <div class="form-inline row p-3 pb-0" v-if="isCode">
            <div class="row">
              <div class="col-lg-3 col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  id="isCode"
                  placeholder="Код"
                  v-model="validate.code"
                />
              </div>
              <button
                class="btn btn btn-primary col-lg-3 col-sm-6"
                type="button"
                :disabled="this.isSend || this.validate.code.length < 3"
                @click="codeValidate"
              >
                Подтвердить операцию
              </button>
            </div>
            <p class="font-warning">
              Для подтверждения операции и подписания
              <a href="../documents/money-transfer-notification" target="_blank"
                >Уведомление об уступки прав</a
              >
              введите код. Он направлен на Вашу почту. Вы с получателем можете
              использовать этот договор для оформления своих правоотношений.
              <a href="../documents/money-transfer" target="_blank"
                >Образец Договора уступки прав.</a
              >
            </p>
          </div>
          <hr />
          <div class="col-sm-12">
            <div>
              <div class="card-header">
                <h5>БАЛАНС СЧЕТОВ</h5>
                <span></span>
              </div>
              <div class="card-body">
                <div id="accordion">
                  <div class="row justify-content-center">
                    <div
                        class=" col-12 col-md-6 col-xl-4 p-2"
                        v-for="(item, index) of money"
                        :key="item.id"
                    >
                      <div class="card-item-amount p-3">
                        <div class="wrapper-amount d-flex justify-content-between">
                          <div class="account-type-sum">
                            <div class="account-type">
                              {{ item.accountType.name }}
                            </div>
                            <div class="sum">
                              {{ Number(item.points).toLocaleString() }} ₽
                            </div>
                          </div>
                          <div class="losses">
                            Убыток:
                            <span>
                              <vue-feather type="arrow-down">

                              </vue-feather>
                              {{
                                Number(item.missedPoints).toLocaleString()
                              }}
                                  ₽
                            </span>
                          </div>
                        </div>
                        <div class="line-percent mt-3 w-100">
                          <div class="line-percent-filling h-100" :style="{ background: colors[index].color, width: colors[index].width }"></div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <p class="mt-3">
                  <b>* Верификация личности  через предоставление подлинного  документа, удостоверяющего личность — паспорт,</b> необходима для соответствия законодательству Российской Федерации и международным требованиям, направленным на противодействие легализации доходов, полученных преступным путём и финансирования терроризма. Проект “Рантье” изначально закладывает практику минимизации рисков претензий со стороны регуляторов. Просим Вас с пониманием отнестись к необходимости верификации для безопасного совершения финансовых операций.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div :class="isActiveModal ? `active` : ``" class="modal-wrapper">
    <div class="modal-overlay" @click="switchModal"></div>
    <div class="modal-content" >
      <div
          class="modal-body d-flex flex-column justify-content-center align-items-center mx-4 my-3">
        <img src="@/assets/svg/info-circle.svg" alt="">
        <h4 class="title my-3" style="color: #444444">
          Ошибка!
        </h4>
        <div class="subtitle text-center mb-3" style="color: #52526CBF">
          Будет доступно в следующих обновлениях
        </div>
        <div class="btn-wrapper text-end w-100">
          <div class="btn btn-primary" @click="switchModal">
            Ок
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertError from "@/components/alert-error.vue";
import AlertSuccess from "@/components/alert-succes.vue";
import UserDataService from "@/services/UserDataService";
import MoneyDataService from "@/services/MoneyDataService";
import OperationsDataService from "@/services/OperationsDataService";

export default {
  data() {
    return {
      isActiveModal: false,
      isCollapsed: {},
      referralCode: "",
      errorMessage: "",
      successMessage: "",
      money: [],
      colors: [
        {
          color: '#7366FF',
          width: 0,
        },
        {
          color: '#F73164',
          width: 0,
        },
        {
          color: '#54BA4A',
          width: 0,
        },
        {
          color: '#16C7F9',
          width: 0,
        },
        {
          color: '#FFA132',
          width: 0,
        },
        {
          color: '#FC4438',
          width: 0,
        },
      ],
      userRecipientEmail: "",
      transferAmount: 0,
      withdrawAmount: 0,
      isCode: false,
      isCodeTransfer: false,
      isSend: false,
      sum: 0,
      profile: {
        user: {},
        recipient: {},
      },
      validate: {
        id: Number,
        code: "",
      },
    };
  },
  components: {
    AlertSuccess,
    AlertError,
  },
  mounted() {
    MoneyDataService.get()
      .then((response) => {
        this.money = response.data;

        this.sum = this.money.reduce((total, item) => {
          return total + parseFloat(item.points);
        }, 0);

        this.colors.forEach((value, index, array) => {
          array[index].width = this.money[index].points / ( this.sum / 100 ) + `%`
        })

      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    switchModal(){
      this.isActiveModal = !this.isActiveModal;
    },
    toggleCollapse(index) {
      for (let key in this.isCollapsed) {
        if (key !== index) {
          this.isCollapsed[key] = false;
        }
      }
      this.isCollapsed[index] = !this.isCollapsed[index];
    },
    clickPayment() {
      if (this.transferAmount < 1) {
        this.errorMessage = "Укажите сумму больше 1 рубля.";
        setTimeout(() => {
      this.errorMessage = ''; 
    }, 3500);
        return;
      }

      if (!this.userRecipientEmail) {
        this.errorMessage = "Укажите Email получателя.";
        setTimeout(() => {
      this.errorMessage = ''; 
    }, 3500);
        return;
      }
      this.isCodeTransfer = true;

      OperationsDataService.get({ typeOperation: 5 }).then((response) => {
        this.validate.id = response.data;
      });

      UserDataService.getProfile(this.userRecipientEmail).then((response) => {
        const profile = response.data;
        localStorage.setItem("userRecipient", JSON.stringify(profile));
      });

      UserDataService.get().then((response) => {
        const profile = response.data;
        localStorage.setItem("userSender", JSON.stringify(profile));
      });

      localStorage.setItem("amount", this.transferAmount);
    },

    clickWithdraw() {
      if (this.withdrawAmount < 30000) {
        this.errorMessage = "Укажите сумму больше 30 000 рублей.";
        setTimeout(() => {
      this.errorMessage = ''; 
    }, 3500);
      } else {
        this.moneyWithdraw();
      }
    },

    codeValidate() {
      this.isSend = true;

      OperationsDataService.codeValidate(this.validate).then((response) => {
        const isValidate = response.data;
        if (isValidate) this.moneyTransfer();
        else {
          this.errorMessage =
            "Не верный код. Попробуйте ввести заново, или обновите страницу и сгенерируйте новый.";
          this.isSend = false;
          setTimeout(() => {
      this.errorMessage = ''; 
    }, 3500);
        }
      });
    },
    moneyTransfer() {
      MoneyDataService.moneyTransfer({
        userRecipientEmail: this.userRecipientEmail,
        amount: Number(this.transferAmount),
      })
        .then(() => {
          this.successMessage = "Перевод выполнен.";
          setTimeout(() => {
      this.successMessage = ''; 
    }, 3500);
          location.reload();
        })
        .catch((e) => {
          this.errorMessage = e.response.data.message;
          console.log(e);
        });
    },

    moneyWithdraw() {
      MoneyDataService.withdraw({
        amount: this.withdrawAmount,
      })
        .then(() => {
          this.successMessage = "Заявка на вывод средств создана";
          setTimeout(() => {
      this.successMessage = ''; 
    }, 3500);
          location.reload();
        })
        .catch((e) => {
          this.errorMessage = e.response.data.message;
          console.log(e);
        });
    },
  },
  computed: {
    isWithdrawAvailable() {
      if (this.money[0]) {
        return Number(this.money[0].points) >= 30000;
      }
    },
  },
};
</script>

<style scoped>
.modal-wrapper {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: all .3s;
  .modal-content {
    background-color: white;
    max-width: 523px;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 2;
    transform: translateY(-30%);
    transition: all .3s;
  }
}
.modal-wrapper.active{
  visibility: visible;
  opacity: 1;
  transition: all .3s;
  .modal-content {
    background-color: white;
    max-width: 523px;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 2;
    transform: translateY(0%);
    transition: all .3s;
  }
}
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.card-body-text {
  font-size: 15px !important;
}
h5 {
  position: relative;
}
.sidebar-toggles {
  position: absolute;
  right: 1%;
  top: 30%;
}
.rotate180 {
  transform: rotate(90deg);
  transition: all .3s;
}
.top-right-alert {
    position: fixed;
    top: 60px; 
    right: 20px;
    z-index: 9999;
}
/* ========== custom css ============= */
.card-item-amount{
  border: 1px solid #F1F3FF;
  border-radius: 5px;
  transition: all .3s;
}
.card-item-amount:hover{
  transform: translateY(-5px);
  transition: all .3s;
}

.account-type{
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #52526CCC;

}
.sum{
  font-size: 28px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #2F2F3B;
}

.losses{
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #212529;
}

.losses span{
  font-size: 12px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
  color: #FC4438;
}
.losses span i{
  width: 12px;
  height: 12px;
}
.line-percent{
  height: 8px;
  background: #E9ECEF;
  border-radius: 15px;
  overflow: hidden;
}

.text-dis{
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.699999988079071px;
  color: #212529BF;

}
.text-disc{
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.699999988079071px;
  color: #212529;
}
.text-p{
  color: #7366FF;
}
label.surprise-text{
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #212529;
}

.surprise-input{
  width: min(100%, 500px);
}

.input-code, .input-code-wrapper{
  margin: 0 auto;
}
</style>
