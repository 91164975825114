<template>
  <div class="wrapper-certificatePageOne">
    <Breadcrumbs main="МОИ СЕРТИФИКАТЫ" title="ДОКУМЕНТЫ"/>
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 class="mb-0">КАК ПОЛУЧИТЬ <span class="text-card-header">АКЦИИ</span></h5>
      </div>
      <div class="card-header-description">
        Скачайте размещённые ниже документы и в точности выполните все шаги, которые описаны в прилагаемой к ним инструкции. А также оплатите сбор на сопутствующие расходы на сумму 1000 руб. (мы обязательно подготовим для вас специальный бонус для компенсации этих затрат)
      </div>
      <div class="card-mini-header">
        Если у вас возникнут вопросы, вы можете их задать в <span class="text-card-header">специальном закрытом чате для акционеров</span>
      </div>
    </div>
    <div class="col-12 mb-5 mt-5">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h5 class="mb-0">МОИ СЕРТИФИКАТЫ</h5>
          <div class="btn-wrapper-certificates">
            <div class="btn btn btn-primary" @click="payment()">
              Оплатить сбор
            </div>
            <div class="mt-2 form-check">
              <input type="checkbox" class="form-check-input" id="isAmountAccount" @change="checkBox"
                     v-model="isAmountAccount" :disabled="isCode">
              <label class="form-check-label" for="isAmountAccount">Оплатить 100% цены с внутреннего счёта.
                <a href="../documents/internal-credit" target="_blank" v-if="isAmountAccount">Заявление
                  на зачет.</a></label>
            </div>
          </div>
        </div>

        <codeConfirmation
            :isCode=isCode
            :validate=validate
            :isAmountAccount=isAmountAccount
            :idSystemPayment=idSystemPayment
            :paymentData=paymentData
            :typePayment=3
        />
        <div class="row p-2" v-if="this.documentsUrl">
          <div class="documents d-flex justify-content-between" v-for="item in documents" :key="item.id">
            <h5 class="card-header card-item-name-document">
              {{ item.name }}
            </h5>
            <a class="card-item-download-document d-flex" :href="backendURL + `/` + item.url">
              <img src="@/assets/images/certificate/download.svg" alt="">
              <div class="text-card-header">Скачать</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import codeConfirmation from "@/components/codeConfirmation.vue";
import MoneyDataService from "@/services/MoneyDataService";
import OperationsDataService from "@/services/OperationsDataService";

export default {
  name: "certificatePageOne",
  components: {codeConfirmation},
  data(){
    return{
      isAmountAccount: false,
      isCode: false,
      backendURL: process.env.VUE_APP_BACKEND_API_BASE,
      idSystemPayment: 0,
      documentsUrl: {
        AFTSAPOS: "",
      },
      validate: {
        id: Number,
        code: '',
      },
      paymentData: {
        name: 'Оплата сбора',
        summa: 1000,
        typePayment: 6,
        isAmountAccount: false
      },
      documents:[
          {
        id: 1,
        name: "Инструкция",
        nameFile: "",
        url: "",
        },
        {
          id: 2,
          name: "Договор купли-продажи",
          nameFile: "",
          url: "/my/files/certificate/ДОГОВОР_КУПЛИ-ПРОДАЖИ _АКЦИЙ.doc",
        },
        {
          id: 3,
          name: "Согласие на обработку персональных данных",
          nameFile: "",
          url: "/my/files/certificate/Согласие_на_обработку_персональных_данных.doc",
        },
        {
          id: 4,
          name: "Распоряжение ВТБ-регистратору",
          nameFile: "",
          url: "/my/files/certificate/Акционеру_формы_документов_19_форма_Распоряжение_о_проведении.doc",
        },
      ],

    }
  },
  mounted(){
    const storedDocuments = localStorage.getItem('documents'); 
    if (storedDocuments) {
        this.documentsUrl = JSON.parse(storedDocuments); 
        console.log("1", this.documentsUrl.AFTSAPOS)
        this.documents[1].url = this.documentsUrl.AFTSAPOS
        this.documents[2].url = this.documentsUrl.PD
        this.documents[3].url = this.documentsUrl.OTCOO
    }
    this.getIdSystemPayment();
  },
  methods: {
    payment(){
      this.isCode = true
      OperationsDataService
          .get({typeOperation: 3})
          .then(response => {
            this.validate.id = response.data;
          })
    },
    getIdSystemPayment(){
      MoneyDataService.getIdPaymentService()
          .then(response => {
            this.idSystemPayment = Number(response.data.idSystemPayment);
          })
          .catch(e => {
            console.log(e);
          });
    },
    checkBox() {
      if (this.isAmountAccount)
        localStorage.setItem('isAmountAccount', true);
      else
        localStorage.removeItem('isAmountAccount')
    },
  }
}
</script>


<style scoped>
.documents{
  align-items: center;
  padding-right: 30px;
  border-bottom: 1px solid #EFEFEF
}
.card-item-download-document{
  gap: 10px;
  cursor: pointer;
}
.card{
  margin: 0 auto;
  max-width: 1020px;
  width: 100%;
}

.card-mini-header{
  font-weight: 400;
  font-size: 14px;
  line-height: 23.8px;
  letter-spacing: 0.7px;
  padding: 0 20px 20px 20px;
}

.card-header-description{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #52526CBF;
  padding: 20px;
}

.text-card-header{
    color: #7366FF;
}
@media screen and (max-width: 580px){
  .card-header{
    flex-direction: column;
  }
  .btn-wrapper-certificates{
    margin-top: 10px;
  }
}
</style>