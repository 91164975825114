import http from "../http-common";

export default {
    get() {
        return http.get('/certificates');
    },
    getMyCertificate() {
        return http.get('/certificates/my');
    },

    isPaymentMoth() {
        return http.get('/certificates/isPaymentMoth');
    },

    accountActivityEndDate() {
        return http.get('/certificates/accountActivityEndDate');
    },

    getStatisticsActivites() {
        return http.get('/certificates/account-activites-static');
    },

    getStatisticsActivitesPercent() {
        return http.get('/certificates/account-activity-in-percent');
    },

    transfer(data) {
        return http.post('/certificates/transfer',data);
    },

    postCertificatesExchange(data) {
        return http.post(`/certificates/exchange/shares?quantity=${data}`);
    },

    getCertificatesRemainder() {
        return http.get('/certificates/remainder');
    },

    paymentCertificateDogovor(data) {
        const idCertificate = data.idCertificate;
        return http.get(`/pdf/certificates/${idCertificate}`, {
            responseType: 'blob',
        });
    },
}

