<template>
  <div class="wrapper-list-projects-item">
    <div class="wrapper-list-projects-item-inner card">
      <div class="item-img">
        <img :src="frontUrl + '/' + imgPreview" alt="#">
      </div>
      <div class="item-description">
        <div class="item-description-low-title">
          {{ category }}
        </div>
        <div class="item-description-title">
          {{ name }}
        </div>
        <div class="item-description-desc">
          {{ truncatedDescription }}
        </div>
        <div class="item-under d-flex justify-content-between mt-3">
          <div class="item-under-left align-items-center text-center d-flex">
            <span v-if="goal > 0">{{ goal }} &#x20bd</span>
          </div>
          <div class="item-under-right" @click="openDetailProject(id)">
            Подробнее
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "listItem",
  data() {
    return {
      frontUrl: process.env.VUE_APP_BACKEND_API_BASE,
      isOpenModal: false, // Состояние модального окна
    };
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    imgPreview: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
    },
    goal: {
      type: String,
      required: true,
    }
  },
  computed: {
    truncatedDescription() {
      return this.desc.length > 100 ? this.desc.substring(0, 100) + '...' : this.desc;
    },
  },
  methods: {
    openDetailProject(id) {
      this.$router.push(`/vote/${id}/`);
    },
    closeModal() {
      this.isOpenModal = false;
    }
  },
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  z-index: 999;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-left-bottom {
  max-width: 650px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

button {
  margin-top: 20px;
}
</style>
