<template>
  <div class="wrapper-project-documents">
    <div class="documents mt-4 pt-4 d-flex justify-content-between" @click="toggleDocuments">
      <span class="documents-header">
        <img src="@/assets/images/pages/project/detail/Info.svg" alt="">
        Документы
      </span>
      <span :class="['arrow', { 'rotate': isOpen }]">
        <img src="@/assets/images/pages/project/detail/Arrow.svg" alt="">
      </span>
    </div>
    <transition name="fade">
      <div v-if="isOpen" class="document-list">
        <div
            class="wrapper-document mt-3"
            v-for="item in documents"
            :key="item.path"
            @click="openDocument(item.path)"
        >
          <img
              src="@/assets/images/pages/project/detail/document.svg"
              alt="Document Icon"
              class="document-icon"
          />
          <span class="document-name">{{ item.rusNameFile }}</span> <!-- Wrap text in a span -->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "documents",
  data() {
    return {
      frontUrl: process.env.VUE_APP_BACKEND_API_BASE,
      isOpen: false,
    }
  },
  props: {
    documents: {
      type: Array,
      required: true
    }
  },
  methods: {
    toggleDocuments() {
      this.isOpen = !this.isOpen;
    },
    openDocument(path) {
      window.open(this.frontUrl + "/" + path, '_blank');
    }
  }
}
</script>

<style scoped>
.wrapper-project-documents {
  position: relative; /* Positioning context for absolute positioning */
}

.documents {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.documents-header {
  display: flex;
  align-items: center;
}

.arrow {
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.arrow.rotate {
  transform: rotate(180deg);
}

.document-list {
  margin-top: 10px;
}

.wrapper-document {
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow-wrap: break-word;
}

.document-icon {
  width: 44px;
  height: 44px;
  margin-right: 8px;
}

.document-name {
  white-space: normal;
  width: 95%;
  overflow-wrap: break-word;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
