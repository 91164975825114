<template>
  <div class="wrapper-modalPayment">
    <div class="card card-left-bottom">
      <div class="card-left-title">
        Для вступления в Целевую программу необходимо внести вступительный взнос.
      </div>
      <div class="card-left-title-bottom">
        Минимальный взнос {{ numberShares }}, дискрепность {{ sharePrice }} ₽
      </div>
      <div class="payment-wrapper d-flex justify-content-between mt-3">
        <div class="payment">
          <label class="form-label">Количество долей</label>
          <input
              class="form-control"
              type="number"
              v-model.number="this.paymentShare"
              @input="updateAmount"
          />
        </div>
        <div class="payment">
          <label class="form-label">Сумма целевого взноса</label>
          <input
              class="form-control"
              type="number"
              v-model.number="this.amount"
              disabled
          />
        </div>
      </div>
      <div class="checkbox p-0">
        <input id="checkbox3" type="checkbox" v-model="document1"/>
        <label class="text-muted ms-3" for="checkbox3">
          <a class="mb-0 pointer" href="/my/files/perevod.doc" download>Заявление о переводе</a>
        </label>
      </div>
      <div class="checkbox p-0">
        <input id="checkbox2" type="checkbox" v-model="document2"/>
        <label class="text-muted ms-3" for="checkbox2">
          <a class="mb-0 pointer" href="/my/files/cp.doc" download>Заявление о приеме в ЦП.</a>
        </label>
      </div>
      <div class="payment-apply mt-3 d-flex justify-content-between">
        <div class="btn btn-primary" @click="paymentTargetShareContribution()" v-if="document1 === true && document2 === true">Внести взнос</div>
        <button class="btn btn-primary" @click="paymentTargetShareContribution()" v-else-if="document1 === false || document2 === false" disabled>Внести взнос</button>
        <div class="checkbox p-0">
          <input type="checkbox" class="d-none" id="checkbox6" v-model="isInternalAccount"/>
          <label class="text-muted ms-3" for="checkbox6" >
            Оплатить 100% цены с внутреннего счёта.
          </label>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ProjectsDataService from "@/services/ProjectsDataService.js";
import {isBoolean} from "lodash/lang.js";

export default {
  name: "modalPayment",

  data(){
    return{
      paymentShare: null,
      amount: 0,
      isInternalAccount: false,
      document2: false,
      document1: false,
      successText:null,
      isContributionsInformationPayment: null,
      paymentContributions: {
        contributionsId: 0,
        isMainAccount: true,
        projectId: 0,
        amount: 0,
        countShares: 0
      },
    }
  },
  props: {
    numberShares:{
      type: Number,
      required: true
    },
    sharePrice:{
      type: Number,
      required: true
    },
    isContributionsInformationPayment:{
      type: Boolean,
      required: true
    },
    contributionsId:{
      type: Number,
      required: true
    },
    projectId:{
      type: Number,
      required: true
    },
  },
  methods: {
    updateAmount() {
      const numericPaymentShare = Number(this.paymentShare);
      const numericSharePrice = Number(this.sharePrice);

      if (!isNaN(numericPaymentShare) && !isNaN(numericSharePrice)) {
        this.amount = Math.floor(numericPaymentShare * numericSharePrice * 10 / 9);
      } else {
        console.error("Оба значения должны быть числовыми.");
      }
    },

    paymentTargetShareContribution(){
        this.paymentContributions = {
          contributionsId: this.contributionsId,
          isMainAccount: this.isInternalAccount,
          projectId: this.projectId,
          amount: this.amount,
          countShares: this.paymentShare
        }
        ProjectsDataService.postContributions(this.paymentContributions)
            .then(response => {
              console.log("1", response.status)
              if (response.status === 201 && this.paymentContributions.isMainAccount === false) {
                window.location.href = response.data.url
              } else if (response.status === 201 && this.paymentContributions.isMainAccount === true) {
                this.$emit('closeModal', false);
                location.reload()
              }
            })
    },
  }
}
</script>
