<template>
    <div class="alert alert-success" role="alert" v-if="text">
      <span v-html="text"></span>
    </div>
</template>

<script>
export default {
  name: 'alert-success',
  props: {
    text: {
      type: String,
      required: true,
    },
  }
}
</script>

<style scoped>
.alert {
  position: fixed !important;
  top: 100px;
  right: 0;
  z-index: 9999;
}

</style>
