<template>
  <div class="card card-item wrapper-grand-coupon h-100 d-flex mb-0 px-4 py-5">
    <img class="img-money" src="@/assets/images/grand-coupon/money.png" alt="">
    <div v-if="status_coupon === null && isPaymentHistory === null" class="content-switch">
      <div class="content-coupon">
        <img src="@/assets/images/grand-coupon/grand-logo.png" alt="">
        <p class="my-2">
          Ожедание получения данных...
        </p>
      </div>
    </div>
    <div v-else-if="this.isPaymentHistory === false" class="content-switch">
      <div class="content-coupon">
        <img src="@/assets/images/grand-coupon/grand-logo.png" alt="">
        <p class="my-2">
          <b>
            Дисконтная премиум-карта «Рантье»
          </b>
          <br>
          <br>
          Получайте 100% кэшбэк со своих покупок!
          <br>
          <br>
          Для получения карты и начисления кэшбэка необходимо приобрести сертификаты «Рантье» на сумму от 10 000 р.
          <br>
          <br>
          Скачайте презентацию премиум-карты «Рантье»
<!--          <a target="_blank" href="https://www.figma.com/design/QGBFu9fJyOQtl4ldjiQDot/Программа-лояльности-GK?node-id=0-1&node-type=canvas&t=ytrVoy5FYLIDvYmD-0">-->
<!--            Ознакомиться с программой-->
<!--          </a>-->
        </p>
        <div class="btn-wrapper">
          <div class="btn btn-coupon mt-3" @click=openModal>
            Инструкция
          </div>
          <div class="btn btn-coupon mt-3" @click="downloadPDF">
            Скачать презентацию
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="status_coupon === false && isPaymentHistory === true" class="content-switch">
      <div class="content-coupon">
        <img src="@/assets/images/grand-coupon/grand-logo.png" alt="">
        <p class="my-2">
          Активируйте программу лояльности
          <br>
<!--          <a target="_blank" href="https://www.figma.com/design/QGBFu9fJyOQtl4ldjiQDot/Программа-лояльности-GK?node-id=0-1&node-type=canvas&t=ytrVoy5FYLIDvYmD-0">-->
<!--            Ознакомиться с программой-->
<!--          </a>-->
        </p>
      </div>
      <div class="btn btn-coupon mt-3" @click="getActivationLink">
        Подключить
      </div>
    </div>
    <div v-else-if="status_coupon === true && isPaymentHistory === true" class="content-switch">
      <div class="content-coupon">
        <img src="@/assets/images/grand-coupon/grand-logo.png" alt="">
        <p class="my-2">
          Начисляется 100% баллов от покупки сертификата.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import GrandDataService from "@/services/GrandDataService"
import modal from "./grandCoupon/modal.vue"
import filePDF from "@/assets/files/PremiumDiscountCardRentier.pdf"

export default {
  components: {
    filePDF,
    modal
  },
  data() {
    return {
      status_coupon: null,
      isPaymentHistory: null,
      isModalOpen: false
    };
  },
  mounted() {
      this.getInfoPayment()
      this.postCheckEmail()
  },
  methods: {
    downloadPDF() {
      const link = document.createElement('a');
      link.href = filePDF;
      link.download = 'PremiumDiscountCardRentier.pdf';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async getInfoPayment() {
      GrandDataService.getInfo()
          .then((response) => {
            this.isPaymentHistory = response.data.result;

          })
    },
    async postCheckEmail() {

       const response = await GrandDataService.getCheckEmail()
          .then((response) => {
            this.status_coupon = response.data.result
          }).catch((e) => {
            console.log(e)
          })

    },

    async getActivationLink() {

      GrandDataService.getLink()
          .then((response) => {

            const activationLink = response.data.link;

            window.open(activationLink, 'newWindow', 'width=500,height=600');

            // window.location.href = activationLink;

          }).catch((e) => {
            console.log(e)
          })
    },
    openModal() {
      this.$emit('openModal');
    }
  }
}
</script>

<style scoped>
.btn-wrapper{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
</style>