<template>
  <div class="wrapper-carousel-all align-items-center">
    <div class="wrapper-carousel d-flex">
      <router-link class="wrapper-carousel-item" :to="{name: 'courses'}">
        <img :src="require(`@/assets/images/carousel/Group${carousel.id}.png`)">
      </router-link>
    </div>
    <div class="btn-wrapper">
      <div class="btn btn-primary" @click="prevCarousel">Назад</div>
      <div class="btn btn-primary" @click="nextCarousel">Вперед</div>
    </div>
  </div>
</template>
<script>
import CarouselItem from "@/components/carousel/carousel-item.vue";

export default {
  name: "carousel",
  components: {
    CarouselItem
  },
  props: {
    interval: {
      type: Number,
      default: 30000
    }
  },
  data() {
    return {
      currentSlideIndex: 1,
      startX: 0,
      endX: 0,
      autoScrollInterval: null,
      carouselArray: [
        { id: 1, name: 'img1', path: "/users/activate" },
        { id: 2, name: 'img2', path: "/career/courses" },
        { id: 3, name: 'img3', path: "/career/courses" },
        { id: 4, name: 'img4', path: "/career/courses" },
        { id: 5, name: 'img5', path: "/career/courses" },
        { id: 6, name: 'img6', path: "/investment/one" },
      ],
      carousel: {
        id: 1,
        path: "/users/activate",
      }
    }
  },
  mounted() {
    this.updateCarousel();
    if (this.interval > 0) {
      this.startAutoScroll();
    }
  },
  beforeDestroy() {
    this.clearAutoScroll();
  },
  methods: {
    startAutoScroll() {
      this.clearAutoScroll();
      this.autoScrollInterval = setInterval(() => {
        this.nextSlide();
      }, this.interval);
    },
    clearAutoScroll() {
      if (this.autoScrollInterval) {
        clearInterval(this.autoScrollInterval);
        this.autoScrollInterval = null;
      }
    },
    updateCarousel() {
      this.carousel = this.carouselArray[this.currentSlideIndex];
    },
    prevCarousel() {
      this.prevSlide();
      console.log("1")
    },
    nextCarousel() {
      console.log("2")
      this.nextSlide();
    },
    prevSlide() {
      if (this.currentSlideIndex > 0) {
        this.currentSlideIndex--;
      } else {
        this.currentSlideIndex = this.carouselArray.length - 1;
      }
      this.updateCarousel();
    },
    nextSlide() {
      if (this.currentSlideIndex < this.carouselArray.length - 1) {
        this.currentSlideIndex++;
      } else {
        this.currentSlideIndex = 0;
      }
      this.updateCarousel();
    },
  }
}

</script>

<style scoped>
.btn{
  cursor: pointer !important;
  margin-bottom: 12px;
  margin-left: 7px;
}
.wrapper-carousel-all{
  gap: 10px;
  display: flex;
  flex-direction: column;
}
</style>