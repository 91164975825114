<template>
  <Breadcrumbs main="ПРОЕКТЫ" path="ПРОЕКТ РАНТЬЕ" title="ПОДТВЕРЖДЕНИЕ"/>
  <div class="card">
    <div class="card-header">
      <h5>ДОКУПИТЬ СЕРТИФИКАТ</h5>
    </div>
    <alert-error v-if="errorMessageOne" :text="errorMessageOne" class="top-right-alert"/>
    <alert-success v-if="successMessageOne" :text="successMessageOne" class="top-right-alert"/>

    <div class="card-body d-flex flex-lg-row flex-column justify-content-center align-items-center align-lg-items-start">
      <form class="needs-validation-2 h-100 ps-0 position-relative">
        <div class="text-gray" v-if="certificateRemainder.remainingValue > 0">Дополните количество сертификатов до целого числа<br />
          Система автоматически рассчитает недостающее количество сертификатов.</div>
          <div class="text-warning" v-else>У вас целое количество сертификатов. Докупка сертификатов невозможна.</div>
        <div class="row g-3 " v-if="certificateRemainder.remainingValue > 0">
          <div class="col-12 col-md-8 position-initial" v-if="certificateRemainder">
            <label class="form-label"   for="validationCustom02">Кол-во сертификатов</label>
            <input class="form-control" disabled type="number" v-model="certificateRemainder.remainingValue" placeholder="Количество">
          </div>

          <div class="col-12 col-md-8 position-initial" v-if="certificateRemainder.remainingValue > 0">
            <label class="form-label" for="validationCustom02">Цена сертификата, ₽</label>
            <input class="form-control" type="number" :value="amountCertificates" placeholder="Цена" disabled>
          </div>
        </div>
        <div class="row mt-3 mb-3 position-initial" v-if="idSystemPayment < 3">
          <div class="form-inline" v-if="certificateRemainder.remainingValue > 0">
            <div class="m-2">
              <button class="btn btn btn-primary" type="button" v-if="!isCodeTwo" @click="clickPaymentTwo">
                Приобрести
              </button>
            </div>
            <div class="mt-2 form-check">
              <input type="checkbox" class="form-check-input" id="isAmountAccount" @change="checkBox"
                     v-model="isAmountAccountTwo" :disabled="isCodeTwo">
              <label class="form-check-label" for="isAmountAccount">Оплатить 100% цены сертификата с внутреннего счёта.
                <a href="../documents/internal-credit" target="_blank" v-if="isAmountAccountTwo">Заявление
                  на зачет.</a></label>
            </div>
          </div>
          <codeConfirmation
              :isCode=isCodeTwo
              :validate=validateTwo
              :isAmountAccount=isAmountAccountTwo
              :idSystemPayment=idSystemPayment
              :paymentData=paymentDataTwo
              :typePayment=3
          />
        </div>
        <hr>

        <p v-if="certificateRemainder.remainingValue > 0">Нажимая "Приобрести", я акцептирую (подписываю) <a href="https://rentier.world/opcion.pdf"
                                                          target="_blank">непубличную оферту</a> и <a
            href="https://rentier.world/pravila.pdf" target="_blank">Правила
          использования сертификатов</a>,
          направленные мне Компанией, путём введения одноразового кода подтверждения.</p>
      </form>

    </div>

  </div>
  <div class="card mt-5">
    <div class="card-header">
      <h5>ПОДТВЕРЖДЕНИЕ</h5>
    </div>
    <alert-error v-if="errorMessageOne" :text="errorMessageOne" class="top-right-alert"/>
    <alert-success v-if="successMessageOne" :text="successMessageOne" class="top-right-alert"/>

    <div class="card-body d-flex flex-lg-row flex-column justify-content-center align-items-center align-lg-items-start">

      <div class="platform-ipo h-100 pe-0 pe-lg-3">
        <div class="project-box position-relative">
          <span class="badge bg-success">Доступен</span>
          <h6>Платформа "Рантье"</h6>
          <p>Сертификаты выпущены в ограниченном количестве</p>
          <div class="row details">
            <div class="col-6"><span>Выпущено </span></div>
            <div class="col-6 font-primary">{{ formatNumeric(countCertificates) }}</div>
            <!-- <div class="col-6"><span>Доступно</span></div>
            <div class="col-6 font-primary">{{ formatNumeric(countCertificatesAvailable) }}</div> -->
            <div class="col-6"><span>Текущая стоимость</span></div>
            <div class="col-6 font-primary">{{ formatNumeric(certificatesPrice) }} &#8381;</div>
          </div>
          <!-- <div class="project-status mt-4 d-flex flex-column">
            <div class="d-flex mb-0">
              <p>{{ balance }}%</p>
              <div class="flex-grow-1 text-end"><span>Осталось</span></div>
            </div>
            <div class="progress" style="height: 5px">
              <div
                  class="progress-bar-animated progress-bar-striped bg-success"
                  role="progressbar"
                  aria-valuenow="10"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="'width:' + balance + '%;'"
              ></div>
            </div>
            <div class="d-flex">
            </div>
          </div> -->
        </div>
      </div>
      <form class="needs-validation h-100 ps-0 ps-lg-3 position-relative">
        <div class="row g-3 ">
          <div class="col-12 col-md-8 pe-5 pe-md-2">
            <label class="form-label">Сумма</label>
            <div class="mb-2 position-relative">
              <input type="number" class="form-control" :disabled="isCode" v-model="summaPayment" min="1000" @input="updateCertificateCount" disabled :max="countCertificateAvailable * certificatePrice" placeholder="Сумма сертификата">

            </div>
          </div>
          <div class="col-12 col-md-8 position-initial">
            <label class="form-label"   for="validationCustom02">Количество</label>
            <input class="form-control" :disabled="isCode" type="number" v-model="certificateCount" @input="updateSummaPayment" placeholder="Количество">
          </div>

          <div class="col-12 col-md-8 position-initial">
            <label class="form-label" for="validationCustom02">Цена сертификата, ₽</label>
            <input class="form-control" type="number" :value="certificatePrice" placeholder="Количество" disabled>
          </div>
        </div>
        <!-- <div class="row mt-3 mb-3 position-initial text-danger">
          Покупка сертификатов приостановлена
        </div> -->
        <div class="row mt-3 mb-3 position-initial" v-if="idSystemPayment < 3">
          <div class="form-inline">
            <div class="m-2">
              <button class="btn btn btn-primary" type="button" v-if="!isCode" @click="clickPayment">
                Купить
              </button>
            </div>
            <div class="mt-2 form-check">
              <input type="checkbox" class="form-check-input" id="isAmountAccount" @change="checkBox"
                     v-model="isAmountAccount" :disabled="isCode">
              <label class="form-check-label" for="isAmountAccount">Оплатить 100% цены сертификата с внутреннего счёта.
                <a href="../documents/internal-credit" target="_blank" v-if="isAmountAccount">Заявление
                  на зачет.</a></label>
            </div>
          </div>
          <codeConfirmation :isCode=isCode :validate=validate :isAmountAccount=isAmountAccount :idSystemPayment=idSystemPayment :paymentData=paymentData :typePayment=3 />
        </div>
        <hr>

        <p>Нажимая "Купить", я акцептирую (подписываю) <a href="https://rentier.world/opcion.pdf"
                                                          target="_blank">непубличную оферту</a> и <a
            href="https://rentier.world/pravila.pdf" target="_blank">Правила
          использования сертификатов</a>,
          направленные мне Компанией, путём введения одноразового кода подтверждения.</p>
      </form>

    </div>

  </div>

</template>

<script>
import CertificateDataService from "@/services/CertificateDataService";
import AlertSuccess from "@/components/alert-succes.vue";
import alertError from "@/components/alert-error.vue";
import UserDataService from "@/services/UserDataService";
import MoneyDataService from "@/services/MoneyDataService";
import OperationsDataService from "@/services/OperationsDataService";

import codeConfirmation from "@/components/codeConfirmation.vue"

export default {
  name: 'buyCertificate',
  data() {
    return {

      showPopover1: true,
      countCertificates: Number,
      countCertificatesAvailable: Number,
      certificatesPrice: Number,
      balance: Number,
      countCertificateAvailable: Number,
      certificatePrice: Number,
      idSystemPayment: 0,
      isAmountAccount: false,
      isButtonDisabled: false,
      progressBarWidth: '100%',
      email: '',
      certificateCount: 1,
      isSummaValid: false,
      countPayment: Number,
      summaPayment: 1000,
      myCertificates: [],
      isAmountAccountTwo: false,
      isCodeTwo: false,
      certificateRemainder: null,
      amountCertificates: 0,
      errorMessageOne: '',
      errorMessage: '',
      successMessageOne: '',
      successMessage: '',

      paymentData: {
        name: 'Покупка сертификата',
        summa: 1000,
        countShares: 1,
        typePayment: 3,
        isAmountAccount: false
      },
      paymentDataTwo: {
        name: 'Покупка сертификата',
        summa: 1000,
        countShares: 0,
        typePayment: 3,
        isAmountAccount: false
      },
      isCode: false,
      validate: {
        id: Number,
        code: '',
      },
      validateTwo: {
        id: Number,
        code: '',
      },
      isCodeTransfer: false,
      isSendTransfer: false,
      transfer: {
        count: Number,
        email: ''
      },
      validateTransfer: {
        id: Number,
        code: '',
      },

    }
  },
  components: {
    AlertSuccess,
    alertError,
    codeConfirmation
  },
  mounted() {
    this.getCertificateRemainder();
    this.getMy();
    CertificateDataService.get()
        .then((response) => {
          const certificate = response.data;
          this.countCertificates = certificate.countCertificate.value;
          this.countCertificatesAvailable =
              certificate.countCertificateAvailable.value;
          this.certificatesPrice = certificate.certificatePrice.value;

          this.balance = Math.round(
              (this.countCertificateAvailable * 100) / this.countCertificates
          );
        })
  },
  methods: {
    async getCertificateRemainder(){
      await CertificateDataService.getCertificatesRemainder()
              .then(response => {
                this.certificateRemainder = response.data;
              })
      this.amountCertificates = Math.ceil(this.certificateRemainder.remainingValue * this.certificateRemainder.certificatePrice.value)
      this.paymentDataTwo.summa = Math.ceil(this.certificateRemainder.remainingValue * this.certificateRemainder.certificatePrice.value)
      this.paymentDataTwo.countShares = this.certificateRemainder.remainingValue
      console.log(this.certificateRemainder)
    },
    formatNumeric: val => `${val}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '\$1 ').replace(/(\.\d+)\s+/g, '\$1'),
    updateCertificateCount() {
      this.certificateCount = Math.floor(this.summaPayment / this.certificatePrice * 10000) / 10000;
      this.paymentData.summa = Math.ceil(this.certificateCount * this.certificatePrice);
      this.paymentData.countShares = this.certificateCount
    },
    updateSummaPayment() {
      this.summaPayment = Math.ceil(this.certificateCount * this.certificatePrice);
      this.paymentData.summa = this.summaPayment;
      this.paymentData.countShares = this.certificateCount
    },
    formatTwo: function (number) {
      return Math.floor(number * 100) / 100;
    },
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
    // short: num => 
    clickTransfer() {
      this.transfer.count = Number(this.transfer.count);
      if (this.transfer.count <= 0) {
        this.errorMessageOne = '';
        this.errorMessage = 'Укажите количество.'
        alertError(`Ввелите количество большо 0`)
        setTimeout(() => {
          this.errorMessage = '';
        }, 3500);
        return;
      }

      if (!this.transfer.email) {
        this.errorMessageOne = '';
        this.errorMessage = 'Укажите Email получателя.'
        setTimeout(() => {
          this.errorMessage = '';
        }, 3500);
        return;

      }

      this.isCodeTransfer = true;

      OperationsDataService
          .get({typeOperation: 6})
          .then(response => {
            this.validateTransfer.id = response.data;
          })

      UserDataService.getProfile(this.transfer.email)
          .then(response => {
            const profile = response.data;
            localStorage.setItem('userRecipient', JSON.stringify(profile));
          })

      UserDataService.get()
          .then(response => {
            const profile = response.data;
            localStorage.setItem('userSender', JSON.stringify(profile));
          })

    },
    codeValidateTransfer() {
      this.isSendTransfer = true;

      OperationsDataService
          .codeValidate(this.validateTransfer)
          .then(response => {
            const isValidate = response.data;
            if (isValidate) this.transferCertificate();
            else {
              this.errorMessage = 'Не верный код. Попробуйте ввести заново, или обновите страницу и сгенерируйте новый.'
              this.isSendTransfer = false;
            }
          })
    },
    transferCertificate() {
      CertificateDataService.transfer(this.transfer)
          .then(() => {
            this.successMessage = 'Перевод выполнен.'
            location.reload();
          })
          .catch(e => {
            this.errorMessage = e.response.data.message;
            console.log(e);
          });
    },
    clickPaymentTwo(){
      this.isCodeTwo = true;
      OperationsDataService
          .get({typeOperation: 3})
          .then(response => {
            this.validateTwo.id = response.data;
          })
    },
    clickPayment() {
      this.isButtonDisabled = true;

      if (this.summaPayment <= 0 || !this.summaPayment) {
        this.errorMessageOne = 'Укажите количество больше нуля.';
        this.errorMessage = '';

        setTimeout(() => {
          this.errorMessageOne = '';
        }, 3500);

        return;
      }

      this.isCode = true;
      OperationsDataService
          .get({typeOperation: 3})
          .then(response => {
            this.validate.id = response.data;
          })
    },
    codeValidate() {
      OperationsDataService
          .codeValidate(this.validate)
          .then(response => {
            const isValidate = response.data;
            if (isValidate) this.choiceAcquiring();
            else this.errorMessage = 'Не верный код. Попробуйте ввести заново, или обновите страницу и сгенерируйте новый.'
          })
    },
    choiceAcquiring() {
      localStorage.setItem('paymentData', JSON.stringify(this.paymentData));
      if (this.isAmountAccount) this.payment();
      if (this.idSystemPayment === 0 && !this.isAmountAccount) window.location.href = '/my/payments/cloudpayments';
      if (this.idSystemPayment === 1 && !this.isAmountAccount) this.payment();
    },

    getMy() {
      MoneyDataService.getIdPaymentService()
          .then(response => {
            this.idSystemPayment = Number(response.data.idSystemPayment);
            this.idSystemPayment = 1;
          })
          .catch(e => {
            console.log(e);
          });

      CertificateDataService.getMyCertificate()
          .then(response => {
            const certificate = response.data;
            this.myCertificates = certificate.myCertificates;

            this.countCertificateAvailable = certificate.countCertificateAvailable.value;
            this.certificatePrice = certificate.certificatePrice.value;
          })
          .catch(e => {
            console.log(e);
          });

      UserDataService.get()
          .then(response => {
            const user = response.data;
            this.email = user.user.email;
          }).catch(e => {
        console.log(e);
      });
    },

    checkSumma() {
      if (!this.summaPayment || this.summaPayment > 50000) {
        this.errorMessage = 'Укажите сумму сертификата от 500 до 50 000 рублей.'
        return false;
      }
      return true;
    },
    validateSumma() {
      this.isSummaValid = this.summaPayment >= 1000 && this.summaPayment <= this.countCertificateAvailable * this.certificatePrice;
    },
    payment() {
      if (!this.checkSumma()) return;
      MoneyDataService
          .payment({
            typePayment: 3,
            selectPaymentSystem: 1,
            isAmountAccount: this.isAmountAccount,
            summa: Number(this.summaPayment),
          })
          .then(response => {
            if (this.isAmountAccount)
              this.successMessage = 'Благодарим за покупку.'
            else {
              window.location.href = response.data.url;
              this.successMessage = 'Перейдите на сайт банка для оплаты ранга.'
            }
          })
          .catch(e => {
            this.errorMessage = 'Ошибка оплаты. ' + e.response.data.message;
          });
    },
    getDogovor(idCertificate) {
      CertificateDataService.paymentCertificateDogovor({idCertificate: idCertificate})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/pdf'});
            const downloadUrl = window.URL.createObjectURL(blob);
            // Создаем тег <a> для скачивания
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `rentier-esr-${idCertificate}.pdf`); // задаем имя файла для скачивания
            document.body.appendChild(link);
            link.click();

            // Очищаем ссылку на URL после скачивания
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
          })
          .catch(e => {
            console.log(e);
          });
    },
    checkBox() {
      if (this.isAmountAccount)
        localStorage.setItem('isAmountAccount', true);
      else
        localStorage.removeItem('isAmountAccount')
    },
    changeCount() {
      this.paymentData.summa = this.summaPayment;
    },
  }
}

</script>

<style scoped>
.top-right-alert {
  position: fixed;
  top: 60px;
  right: 20px;
  z-index: 9999;
}
.platform-ipo{
  width: clamp( 100px, 100% , 500px);
}
.card{
  margin: 0 auto;
  width: min( 100%, 1400px);

}
.needs-validation{
  width: clamp( 100px, 100% , 500px);
}
.needs-validation-2{
  width: clamp( 100px, 100% , 900px);
}
.text-gray{
  color: #212529BF;

}
</style>