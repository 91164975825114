<template>
<div class="wrapper-list-project">
  <Breadcrumbs title="СПИСОК ПРОЕКТОВ" class="mt-3" />
  <div class="container-fluid" v-if="this.profile">
    <div class="row row-list row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-xl-3">
      <listItem
          v-for="item in projects"
          :id="item.id"
          :imgPreview="item.preview"
          :category="item.category.name"
          :name="item.name"
          :desc="item.shortDescription"
          :goal="item.price"
      />
    </div>
  </div>
</div>
</template>

<script>
import listItem from "@/components/projects/listItem.vue";
import ProjectsDataService from "@/services/ProjectsDataService.js";
import userDataService from "@/services/UserDataService.js";
export default {
  name: "ProjectList",
  data(){
    return{
      projects: null,
      page: 1,
      pageSize: 6,
      profile: null
    };
  },
  components: {
    listItem
  },
  mounted(){
    this.getProject()
    this.getUser()
  },
  methods:{
    async getUser(){
      userDataService.get()
          .then(response => {
            this.profile = response.data
          })
    },
    async getProject(){
      await ProjectsDataService.get(this.page, this.pageSize)
          .then(response => {
            this.projects = response.data;
            this.getUser()
          });
    },
  },
}
</script>