<template>
<div class="wrapper-detail-project">
  <Breadcrumbs title="ПРОЕКТЫ ДЕТАЛЬНО" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-xl-7">
        <div class="card" v-if="this.project">
          <div class="preview">
            <img :src="frontUrl + '/' + this.actualItemSlide.path" alt="" class="">
          </div>
<!--            <div class="slider-container" @wheel.prevent="handleWheel">-->
<!--              <div class="slider">-->
<!--                <button class="arrow left" @click="prevSlide">←</button>-->
<!--                <div class="slides" ref="slides" @mousedown="startDrag" @touchstart="startDrag" @mouseup="endDrag" @touchend="endDrag" @mousemove="drag" @touchmove="drag">-->
<!--                  <div class="slide" v-for="(item, index) in project.images" :key="index" @click="openSlide(item)">-->
<!--                    <img :src="frontUrl + '/' + item.path" alt="" />-->
<!--                  </div>-->
<!--                </div>-->
<!--                <button class="arrow right" @click="nextSlide">→</button>-->
<!--              </div>-->
<!--            </div>-->
        </div>
      </div>
      <div class="col-sm-12 col-xl-5 left-menu" v-if="project">
        <div class="card card-left" v-if="this.project">
          <div class="title">{{ this.project.name }}</div>
          <div class="progressBar mt-4" v-if="this.project.sharePrice > 0">
            <div class="progress" :style="{ width: progressPercentage + '%' }"></div>
            <div class="progressDot" :style="{ left: progressPercentage + '%' }"></div>
            <div class="progressText" :style="{ left: progressPercentage + '%' }">{{ project.fundraisingVolume }} &#x20bd</div>
          </div>
          <div class="progressInfo d-flex justify-content-between" v-if="this.project.sharePrice > 0">
            <span class="progressInfo-title">Цель: </span>
            <span class="progressInfo-number">{{ project.price }} &#x20bd</span>
          </div>
          <div class="author-city-category">
            <div class="author d-flex justify-content-between">
              <div class="author-title">
                Автор:
              </div>
              <div class="author-name">
                {{ this.project.author }}
              </div>
            </div>
            <div class="author d-flex justify-content-between mt-3">
              <div class="author-title">
                Город:
              </div>
              <div class="author-name">
                {{ this.project.city }}
              </div>
            </div>
            <div class="author d-flex justify-content-between mt-3">
              <div class="author-title">
                Вид проекта:
              </div>
              <div class="author-name">
                {{ this.project.category.name }}
              </div>
            </div>
          </div>
          <div class="conditions" v-if="this.project.sharePrice > 0">
            <div class="conditions-title">
              Условие проекта
            </div>
            <div class="conditions-item-wrapper mt-4">
              <div class="row d-flex justify-content-center">
                <div class="conditions-top  d-flex justify-content-around">
                  <div class="conditions-item  mt-3 d-flex justify-content-center">
                    <div class="conditions-item-inner">
                      <div class="conditions-item-title">
                        Минимальная сумма
                      </div>
                      <div class="conditions-item-name">
                        {{ this.project.sharePrice }} &#x20bd
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <shareProject />
          <documents
              :documents = this.project.documents
          />
        </div>
        <div class="card card-left-bottom" v-if="this.project.sharePrice > 0">
          <div class="card-left-title" >
            Купить сертификаты
          </div>
          <AlertSuccess v-if="this.successMessage" :text="this.successMessage" />
          <AlertError v-if="this.errorMessage" :text="this.errorMessage" />
          <div class="payment-wrapper d-flex justify-content-between mt-3">
            <div class="payment">
              <label class="form-label">Количество сертификатов</label>
              <input
                  class="form-control"
                  type="text"
                  @input="updateAmount"
                  v-model="this.paymentShare"
              />
            </div>
            <div class="payment">
              <label class="form-label">Сумма</label>
              <input
                  class="form-control"
                  type="text"
                  v-model="this.amount"
                  disabled
              />
            </div>
          </div>
          <div class="payment-apply mt-3 d-flex justify-content-between">
            <button class="btn btn-primary" @click="shareContribution()" :disabled="isSuccessOption">Купить опцион</button>
<!--            <div class="btn btn-primary" v-else-if="this.isContributionsInformationPayment === true" @click="shareContribution">Внести паевой взнос</div>-->
             <div class="checkbox p-0">
              <input id="checkbox1" class="d-none" type="checkbox" v-model="isSuccessOption"/>
              <label class="text-muted ms-3" for="checkbox1">
                <a href="/my/files/Договор_купли_продажи_сертификата_оферта.docx" download>Ссылка на Опционное соглашение</a>
              </label>
            </div>         
<!-- <div class="checkbox p-0">
              <input id="checkbox1" class="d-none" type="checkbox" v-model="isInternalAccountMain"/>
              <label class="text-muted ms-3" for="checkbox1">
                Оплатить 100% цены с внутреннего счёта.
              </label>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-xl-12">
        <div class="card card-desc" v-if="this.project">
          {{this.project.description}}
        </div>
      </div>
    </div>
  </div>
  <!-- Модальные окна -->
<!--  <div v-if="isOpenModal && isContributionsInformationPayment === false" class="modal-overlay" @click.self="closeModal">-->
<!--    <div>-->
<!--      <modalPayment-->
<!--        :numberShares = "numberShares"-->
<!--        :sharePrice = "this.project.sharePrice"-->
<!--        :contributionsId = "this.contributions.contributions.id"-->
<!--        :isContributionsInformationPayment = "this.isContributionsInformationPayment"-->
<!--        :projectId = "this.project.id"-->
<!--        @closeModal="closeModal"-->
<!--      />-->
<!--    </div>-->
<!--  </div>-->
</div>
</template>

<script>
import ProjectsDataService from "@/services/ProjectsDataService.js";
import shareProject from "@/components/projects/detail/shareProject.vue"
import modalPayment from "@/components/projects/detail/modalPayment.vue"
import AlertSuccess from "@/components/alert-succes.vue"
import AlertError from "@/components/alert-error.vue"
import documents from "@/components/projects/detail/documents.vue"
import modalDocuments from "@/components/projects/detail/modalDocuments.vue"
import MoneyDataService from "@/services/MoneyDataService";
export default {
  name: "ProjectDetail",
  data(){
    return{
      id: this.$route.params.id,
      currentIndex: 0,
      isDragging: false,
      isOpenModal: false,
      isInternalAccountMain: false,
      isOpenModalDocuments: false,
      successMessage: "",
      errorMessage: null,
      paymentShare: null,
      isSuccessOption: false,
      amount: 0,
      isContributionsInformationPayment: null,
      numberShares: 0,
      paymentShares: {
        isMainAccount: true,
        projectId: 0,
        amount: 0,
        countShares: 0
      },
      paymentContributions: {
        contributionsId: 0,
        isMainAccount: true,
        projectId: 0,
        amount: 0,
        countShares: 0
      },
      contributions: [],
      startX: 0,
      actualItemSlide: null,
      scrollLeft: 0,
      project: null,
      frontUrl: process.env.VUE_APP_BACKEND_API_BASE,
    }
  },
  components:{
    shareProject,
    modalPayment,
    documents,
    modalDocuments,
    AlertSuccess,
    AlertError
  },
  computed: {
    progressPercentage() {
      if (this.project && this.project.price > 0) {
        return Math.min((this.project.fundraisingVolume / this.project.price) * 100, 100);
      }
      return 0;
    },

  },
  async mounted() {
    try {
      await this.projectId()
      await this.getProject()
      // await this.getIsProjectContributions(this.project.id)
      // await this.getProjectsContributions(this.project.id);
    } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
    }
  },
  methods: {
    async shareContribution() {
      this.paymentShares = {
        typePayment: 5,
        selectPaymentSystem: 1,
        isAmountAccount: this.isInternalAccountMain,
        projectId: this.project.id,
        summa: this.amount,
        countShares: Number(this.paymentShare)
      };
      await MoneyDataService.getIdPaymentService()
          .then(response => {
            this.paymentShares.selectedPaymentSystem = response.data.idSystemPayment
          })
      await MoneyDataService.payment(this.paymentShares)
          .then(response => {
            console.log("Response received:", response);
            if (response.status === 201 && this.paymentShares.isAmountAccount === false) {
              window.location.href = response.data.url;
            } else if (response.status === 201 && this.paymentShares.isAmountAccount === true) {
              this.successMessage = "Операция выполнена успешно!";
              setTimeout(() => {
                location.reload()
              }, 4000);
            } else {
              console.log("Unexpected response status:", response.status);
              this.errorMessage = response.data.message || "Неизвестная ошибка";
            }
            setTimeout(() => {
              this.successMessage = null;
              this.errorMessage = null;
            }, 4000);
          })
          .catch(error => {
            if (error.response) {
              this.errorMessage = error.response.data.message || "Произошла ошибка";
            } else if (error.request) {
              this.errorMessage = "Сервер не ответил. Пожалуйста, попробуйте позже.";
            } else {
              this.errorMessage = "Ошибка: " + error.message;
            }
            setTimeout(() => {
              this.errorMessage = null;
            }, 4000);
          });

    },
    updateAmount(){
      this.amount = Math.floor(this.paymentShare * this.project.sharePrice )
    },
    projectId() {
      this.id = this.$route.params.id;
    },
    getIsProjectContributions(id){
      ProjectsDataService.getIsProjectsContributions(id)
      .then((response) => {
        this.isContributionsInformationPayment = response.data
      })
    },
    // openDetailProject(id) {
    //   this.isOpenModal = true;
    //   this.getProjectsContributions(this.project.id)
    // },
    closeModal() {
      this.isOpenModal = false;
    },
    nextSlide() {
      if (this.currentIndex < this.project.items.length - 1) {
        this.currentIndex++;
        this.updateSlidePosition();
      }
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.updateSlidePosition();
      }
    },
    openSlide(item) {
      console.log('Открыть элемент:', item);
    },
    updateSlidePosition() {
      const slides = this.$refs.slides;
      const slideWidth = slides.clientWidth / this.project.items.length;
      slides.style.transform = `translateX(-${this.currentIndex * slideWidth}px)`;
    },
    startDrag(e) {
      this.isDragging = true;
      this.startX = e.pageX || e.touches[0].pageX;
      this.scrollLeft = this.$refs.slides.scrollLeft;
    },
    endDrag() {
      this.isDragging = false;
    },
    drag(e) {
      if (!this.isDragging) return;
      e.preventDefault();
      const x = e.pageX || e.touches[0].pageX;
      const walk = (x - this.startX);
      this.$refs.slides.scrollLeft = this.scrollLeft - walk;

      if (walk > 100 && this.currentIndex > 0) {
        this.prevSlide();
        this.endDrag();
      } else if (walk < -100 && this.currentIndex < this.project.items.length - 1) {
        this.nextSlide();
        this.endDrag();
      }
    },
    handleWheel(event) {
      if (event.deltaY > 0) {
        this.nextSlide();
      } else {
        this.prevSlide();
      }
    },
    async getProject() {
      if (this.id) {
        await ProjectsDataService.getById(this.id)
            .then(response => {
              this.project = response.data.project;
              this.actualItemSlide= response.data.project.images[0];
              console.log(this.project)
            })
            .catch(error => {
              console.error('Ошибка при получении проектов:', error);
            });
      }
    },
  },
}
</script>


<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  z-index: 999;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  margin-top: 20px;
}
</style>
