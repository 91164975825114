<template>
  <div class="wrapper-certificate-modal">
    <div class="card">
      <div class="card-header-certificate d-flex justify-content-between">
        <div class="card-header-left">
          Обменять сертификаты
        </div>
        <div class="card-header-right" @click="closeModal()">
          <img src="@/assets/images/payment/close.svg" alt="#">
        </div>
      </div>
      <div class="form-group">
        <label class="col-form-label">Кол-во сертификатов</label>
        <input class="form-control" type="number" required="" placeholder="0" v-model.number="countCertificate">
        <label class="col-form-label" v-if="!errorMessage">Обмену подлежит только целое число Ваших сертификатов</label>
        <label class="col-form-label text-danger" v-else>{{ errorMessage }}</label>
      </div>
      <div class="btn-wrapper mt-3">
        <div class="btn-left" @click="postCountCertificate()">
          Заявить о праве требования привилегированных акций
        </div>
        <div class="btn-right btn btn-danger" @click="closeModal()">
          Отмена
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CertificateDataService from "@/services/CertificateDataService";

export default {
  name: "certificateModal",
  data(){
    return{
      countCertificate: 0,
      errorMessage: "",
      documents: null,
    }
  },
  methods:{
    closeModal(){
      this.$emit('closeModal', false);
      this.countCertificate = 0;
    },
    postCountCertificate(){
      if (this.countCertificate > 0){
        CertificateDataService.postCertificatesExchange(this.countCertificate)
            .then(response => {
              this.documents = response.data
              localStorage.setItem('documents', JSON.stringify(this.documents));
              if (response.status === 201 ) {
                this.$router.push('/investment/certificateDocument');
              } else {
                console.log("Unexpected response status:", response.status);
                this.errorMessage = response.data.message || "Неизвестная ошибка";
              }
              setTimeout(() => {
                this.successMessage = null;
                this.errorMessage = null;
              }, 4000);
            })
            .catch(error => {
              if (error.response) {
                this.errorMessage = error.response.data.message || "Произошла ошибка";
              } else if (error.request) {
                this.errorMessage = "Сервер не ответил. Пожалуйста, попробуйте позже.";
              } else {
                this.errorMessage = "Ошибка: " + error.message;
              }
              setTimeout(() => {
                this.errorMessage = null;
              }, 4000);
            });
      } else {
        this.errorMessage = "Значение должно быть больше 0";
        setTimeout(() => {
          this.errorMessage = null;
        }, 4000);
      }
    },
  }
}
</script>

<style scoped>
.wrapper-certificate-modal{
  position: fixed;
}
.card-header{
  gap: 40px;
}
</style>