<template>
  <div>
    <Breadcrumbs title="ПРОФИЛЬ" main="ПОЛЬЗОВАТЕЛЬ" />
    <div class="alert-profile" :class="{'error': error }" >
      {{ errorText }}
    </div>
    <div class="container-fluid">
      <!--       <ChangeCover />-->
      <div class="wrapper-cover-slider">
        <!--        <img-->
        <!--            v-if="!imageCover && !isLoadingCover"-->
        <!--            class="wrapper-cover-image"-->
        <!--            src="@/assets/images/banner/background.png"-->
        <!--            alt="BackgroundImage"-->
        <!--            style="width: 100%; height: 200px"-->
        <!--        />-->
        <!--        <img-->
        <!--            v-else-if="!isLoadingCover"-->
        <!--            ref="image_cover"-->
        <!--            :class="{ 'adapt': !adapt }"-->
        <!--            class="wrapper-cover-image"-->
        <!--            :src="FRONT_URL + this.imageCover"-->
        <!--            alt="BackgroundImage"-->
        <!--            @load="setImageStyles"-->
        <!--        />-->
        <Carousel :carouselArray="carouselArray" :interval="8000"/>
        <div v-if="isLoadingCover" class="wrapper-loader d-flex justify-content-center align-items-center">
          <div class="loader-cover"></div>
        </div>
        <div class="btn-cover d-flex justify-content-center align-items-center">
          <div>
            <!--            <label for="input-file" class="m-0">-->
            <!--              <vue-feather type="edit-2" class="edit"></vue-feather>-->
            <!--              Изменить обложку-->
            <!--            </label>-->
            <!--            <input class="input-file-cover d-none" id='input-file' type="file" @change="handleFileCover">-->
          </div>
        </div>
      </div>
      <div class="user-profile">
        <div class="row">
          <tourProfile
            :fio="fio"
            :firstName="firstName"
            :rank="rank"
            :telegram="telegram"
            :email="email"
            :dateRegister="dateRegister"
            :imageAvatar="imageAvatar"
            :isLoadingAvatar="isLoadingAvatar"
            :handleFileAvatar="handleFileAvatar"
          />
        </div>
      </div>
      <div class="wrapper-profile">
        <div class="row">
          <div class="wrapper-mobile mt-3 card-height opacity col-12 col-xl-12 col-xxl-6">
            <div class="wrapper-mobile-inner w-100 h-100 overflow-hidden">
              <div class="wrapper-mobile-left">
                <div class="wrapper-mobile-left-title">
                  "РАНТЬЕ" теперь всегда с вами!
                </div>
                <div class="wrapper-mobile-left-description col-12 col-md-6 col-xl-6 col-xl-7">
                  Откройте для себя все возможности "РАНТЬЕ" в мобильном приложении — ваш путь к эффективному инвестированию теперь всегда под рукой.
                </div>
                <div class="wrapper-mobile-left-btn mt-3 btn btn-dark" @click="openApplication()">
                  Подробнее
                </div>
              </div>
              <div class="wrapper-mobile-right col-12 col-xl-6">
                <img src="@/assets/images/user/backMobile.svg" class="wrapper-mobile-img-tel" alt="">
                <img src="@/assets/images/user/Mobile-dark.svg" class="wrapper-mobile-img-telDark" alt="">
              </div>
            </div>
          </div>
          <div class="wrapper-bot mt-3 card-height opacity col-12 col-xl-12 col-xxl-6">
            <div class="wrapper-bot-inner w-100 h-100 overflow-hidden">
              <div class="wrapper-mobile-left">
                <div class="wrapper-mobile-left-title col-xxl-8">
                  Присоединяйтесь к нашему Телеграмм-боту и начните зарабатывать прямо сейчас!
                </div>
                <div class="wrapper-mobile-left-description mt-3 col-12 col-md-6 col-xl-6 col-xl-7">
                  Присоединяйтесь к Телеграмм-боту "РАНТЬЕ" и откройте для себя уникальные возможности для роста и заработка.
                </div>
                <div class="wrapper-mobile-left-btn mt-3 btn btn-primary " @click="openTgBOt()">
                  Перейти в чат-бот
                </div>
              </div>
              <div class="wrapper-mobile-right col-12 col-xl-6">
                <img src="@/assets/images/user/backBot.svg" class="wrapper-mobile-img-telBot" alt="">
                <img src="@/assets/images/user/Mobile-darkBot.svg" class="wrapper-mobile-img-telDarkBot" alt="">
              </div>
            </div>
          </div>
          <div class="mt-3 card-height opacity col-12 col-xl-12 col-xxl-12">
            <div class="card-cooperative d-flex justify-content-between overflow-hidden">
              <div class="card-cooperative-left col-12 col-xl-7 ">
                <div class="card-cooperative-left-title mt-5">
                  Инвестируйте в будущее с корпоративной платформой "РАНТЬЕ"!
                </div>
                <div class="card-cooperative-left-description mt-4">
                  Корпоративный сайт "РАНТЬЕ" — это современная инвестиционная платформа,
                  созданная для долгосрочного роста вашего капитала.
                  Здесь вы можете поддерживать перспективные проекты и покупать активы для стабильного дохода,
                  без необходимости покупать ежемесячные сертификаты.
                </div>
                <div class="card-cooperative-left-btn d-flex mt-4">
                  <div class="card-cooperative-left-btn-left cursor-pointer" @click="openAlert()">
                    Зарегистрироваться через РАНТЬЕ
                  </div>
                  <div class="card-cooperative-left-btn-right cursor-pointer" @click="openAlert()">
                    Авторизироваться через РАНТЬЕ
                  </div>
                </div>
              </div>
              <div class="card-cooperative-right col-12 col-xl-5 d-flex">
                <img src="@/assets/images/user/back1.svg" alt="#">
                <img src="@/assets/images/user/note.svg" class="card-cooperative-right-img" alt="#">
              </div>
            </div>
          </div>
          <div ref="card1" class="col-12 col-md-12 col-xl-12 col-xxl-8 mt-4 opacity" >
            <Events />
          </div>
          <div v-if="meeting" class="col-12 col-md-6 card-height col-xl-6 col-xxl-4 mt-3 opacity" >
            <div v-if="userId !== IND_USER_ID" class="card card-body img-bg w-100 h-100 overflow-hidden">
              <div class="img-project h-70 d-flex justify-content-center">
                <div class="w-100 h-100 overflow-hidden">
                  <img
                      class="img-project-img w-100"
                      :src="this.FRONT_URL + meeting.titleImagePath"
                      alt=""
                  />
                </div>
              </div>
              <div class="pos">
                <div class="title text-center mt-3">
                  <b> Актуальная информация о ближайших событиях </b>
                </div>
                <div class="button-p text-center mt-3">
                  <router-link :to="'/events/list/' + meeting.id" class="btn btn-primary">Подробнее</router-link>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-12 col-md-12 col-xl-6 col-xxl-4 mt-4 opacity" >
            <EventLook />
          </div>
          <div ref="card2" class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-4 opacity">
            <Ticket />
          </div>
          <div ref="card3" class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-4 opacity">
<!--            <TopProjects :topProjects="topProjects" />-->
          </div>
        </div>
        <div class="row">
        </div>
        <div class="col-12 col-md-12 col-xl-12 col-xxl-8 mt-3">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserDataService from "@/services/UserDataService";
import CertificateDataService from "@/services/CertificateDataService"

import axios from "axios";
import tourProfile from "./tourProfile.vue";
import Ticket from "@/pages/users/profile/Ticket.vue";
import VerificationCard from "./widget/VerificationCard.vue";
import PlatformAccess from "./widget/PlatformAccess.vue";
import Capital from "./widget/Capital.vue";
import OrderthismonthCard from "./widget/OrderthismonthCard/OrderthismonthCard.vue";
import Balance from "./widget/OrderthismonthCard/Balance.vue";
import MonthlyIncome from "./widget/OrderthismonthCard/MonthlyIncome.vue";
import PersonalActivity from "./widget/OrderthismonthCard/PersonalActivity.vue";
import CertificateValue from "./widget/OrderthismonthCard/CertificateValue.vue";
import ChangeCover from "./ChangeCover.vue";
import { fetchData } from "@/data/comon";
import Refpath from "./widget/Refpath.vue";
import Active from "./widget/MyActive.vue";
import Events from "./widget/Events.vue";
import EventLook from "./widget/EventLook.vue";
import TopProjects from  "./TopProjects.vue";
import GrandCoupon from "./widget/GrandCoupon.vue"
// import personalActivity from './widget/PersonalActivity.vue'
import ChatsDataService from "@/services/ChatsDataService";
import Carousel from "@/components/carousel/carousel.vue"
export default {
  name: "userProfile",
  data() {
    return {
      carouselArray: [
        {
          id: 1,
          name: 'img1'
        },
        {
          id: 2,
          name: 'img2'
        },
        {
          id: 3,
          name: 'img3'
        },
        {
          id: 4,
          name: 'img4'
        },
        {
          id: 5,
          name: 'img5'
        },
        {
          id: 6,
          name: 'img6'
        },
      ],
      userId: 1,
      fio: "",
      firstName: "",
      rank: "",
      telegram: "",
      email: "",
      dateRegister: "",
      commentVerification: "",
      statusVerification: null,
      idappeall: null,
      btn: 1,
      meeting: [],
      parent: [],
      showPopover: false,
      imageCover: null,
      imageAvatar: null,
      isLoadingCover: false,
      isLoadingAvatar: false,
      error: false,
      dataUser: {
        respondingUserId: Number,
      },
      errorText: '',
      adapt: false,
      topProjects: [],
      statisticsActivites: [],
      statisticsActivitesPercent: 0,
      accountActivityValueEndDate: '',
      isVisible: {
        card1: false,
        card2: false,
        card3: false,
        card4: false,
        card5: false,
        card6: false,
      },
      animationPlayed: {
        card1: false,
        card2: false,
        card3: false,
        card4: false,
        card5: false,
        card6: false,
      },
    };
  },
  components: {
    tourProfile,
    Ticket,
    Carousel,
    VerificationCard,
    PlatformAccess,
    Capital,
    OrderthismonthCard,
    Balance,
    MonthlyIncome,
    PersonalActivity,
    CertificateValue,
    ChangeCover,
    Refpath,
    Active,
    Events,
    EventLook,
    TopProjects,
    GrandCoupon
  },
  mounted() {
    this.getUser();
    this.getNearestMeeting();
    this.getStatisticsActivites();
    this.accountActivityEndDate();

    fetchData();
    // this.fetchBackgroundImage()
  },
  methods: {
    openAlert(){
      alert("Будет доступно в обновлении")
    },
    openTgBOt(){
      // this.$router.push('/documentation/telegram/bot');
      alert('Кнопка будет доступная позже!');
    },
    openApplication(){
      this.$router.push('/documentation/application');
    },
    initIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {

          if (entry.isIntersecting) {
            if (entry.target === this.$refs.card1) {
              this.isVisible.card1 = true;
              this.animationPlayed.card1 = true;
            } else if (entry.target === this.$refs.card2) {
              this.isVisible.card2 = true;
              this.animationPlayed.card2 = true;
            } else if (entry.target === this.$refs.card3) {
              this.isVisible.card3 = true;
              this.animationPlayed.card3 = true;
            } else if (entry.target === this.$refs.card4) {
              this.isVisible.card4 = true;
              this.animationPlayed.card4 = true;
            } else if (entry.target === this.$refs.card5) {
              this.isVisible.card5 = true;
              this.animationPlayed.card5 = true;
            } else if (entry.target === this.$refs.card6) {
              this.isVisible.card6 = true;
              this.animationPlayed.card6 = true;
            }
          } else {
            if (entry.target === this.$refs.card1) {
              this.isVisible.card1 = false;
            } else if (entry.target === this.$refs.card2) {
              this.isVisible.card2 = false;
            } else if (entry.target === this.$refs.card3) {
              this.isVisible.card3 = false;
            } else if (entry.target === this.$refs.card4) {
              this.isVisible.card4 = false;
            } else if (entry.target === this.$refs.card5) {
              this.isVisible.card5 = false;
            } else if (entry.target === this.$refs.card6) {
              this.isVisible.card6 = false;
            }
          }
        });
      }, options);

      this.observer.observe(this.$refs.card1);
      this.observer.observe(this.$refs.card2);
      this.observer.observe(this.$refs.card3);
      this.observer.observe(this.$refs.card4);
      this.observer.observe(this.$refs.card5);
      this.observer.observe(this.$refs.card6);
    },

    async getStatisticsActivites() {
      await CertificateDataService.getStatisticsActivites()
          .then((response) => {
            this.statisticsActivites = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
    },

    async accountActivityEndDate() {
      await CertificateDataService.accountActivityEndDate()
          .then((response) => {
            this.accountActivityValueEndDate = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
    },

    openChat(respondingUser){
      this.dataUser = {
        respondingUserId : respondingUser
      }
      ChatsDataService.postChats(this.dataUser)
          .then((response) => {
            const chatId = response.data.id;

            this.$router.push({ name: 'chatId', params: { chatId } });
          })
    },
    setImageStyles() {

      const image = this.$refs.image_cover;
      const width = image.naturalWidth;
      const height = image.naturalHeight;

      if (width > height) {
        this.adapt = true
      } else {
        this.adapt = false
      }

      image.onload = () => {
        const width = image.naturalWidth;
        const height = image.naturalHeight;

        console.log(width)
        console.log(height)

        if (width > height) {

          image.style.width = '100%';
        } else {

          image.style.height = '100%';
        }
      };
    },

    async getNearestMeeting() {
      await UserDataService.getNearestMeeting()
        .then((response) => {
          this.meeting = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getUser() {

      this.isLoadingCover = true

      await UserDataService.get()
        .then((response) => {
          const user = response.data;
          this.userId = user.user.id
          this.parent = user.user.parent;
          this.firstName = user.firstName;
          this.fio =
            user.lastName + " " + user.firstName + " " + user.middleName;
          this.rank = user.user.rank ? user.user.rank.name : "Ранг отсутствует";
          this.telegram = user.telegram ? user.telegram : "отсутствует";
          this.email = user.user.email;
          if (user.passportVerification) {
            this.commentVerification = user.passportVerification.comment;
            this.statusVerification = user.passportVerification.status.id;
            this.idappeall = user.passportVerification.id;
          }

          this.imageCover = user.backgroundImage;
          this.imageAvatar = user.largeAvatar;

          this.isLoadingCover = false

          this.dateRegister = new Date(user.created_at).toLocaleDateString(
            "ru-Ru"
          );

          this.setImageStyles()
        })
        .catch((e) => {

          this.isLoadingCover = false

        });
    },

    async handleFileCover(event) {
      const file = event.target.files[0]
      const formDataDetail = new FormData();
      formDataDetail.append("file", file)

      this.isLoadingCover = true

      await UserDataService.fileUploadsCover(formDataDetail)
          .then((response) => {
            this.isLoadingCover = false;
            this.getUser()
          })
          .catch((e) => {
            console.log(e.response.data.message)
            this.errorText = e.response.data.message
            this.error = true
            this.isLoadingCover = false;
            setTimeout(() => {
              this.error = false
            }, 5000)
            console.log(e)

          })
    },
    async handleFileAvatar(event) {
      const file = event.target.files[0]
      const formDataDetail = new FormData();
      formDataDetail.append("file", file)

      this.isLoadingAvatar = true

      await UserDataService.fileUploadsAvatar(formDataDetail)
          .then((response) => {
            console.log(response)
            this.isLoadingAvatar = false;
            this.getUser()
          })
          .catch((e) => {
            console.log(e.response.data.message)
            this.errorText = e.response.data.message
            this.error = true
            this.isLoadingAvatar = false;
            setTimeout(() => {
              this.error = false
            }, 5000)
            console.log(e)

          })
    }
  },
};
</script>
<style scoped>

.isVisible{
  opacity: 0;
}

.slide-enter-right {
  animation: slide-enter-right 0.8s ease-out;
}

.slide-enter-top {
  animation: slide-enter-top 0.8s ease-out;
}

.slide-enter-left {
  animation: slide-enter-left 0.8s ease-out;
}
.wrapper-mobile-inner{
  position: relative;
}

.wrapper-mobile-left-description{
  font-size: 18px;
  z-index: 999;
  font-weight: 400;
  line-height: 26px;
  margin-top: 15px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
}

.wrapper-mobile-left{
  margin-left: 30px;
}

.wrapper-mobile-left-title{
  font-size: 24px;
  margin-top: 81px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
}

.wrapper-mobile-img-telDark{
  z-index: 1;
  top: 62px;
  right: 20px;
  position: absolute;
}

.card-cooperative-right{
  position: relative;
}

.card-cooperative-left-btn-right{
  background: linear-gradient(90deg, #6C5CF7 0%, #563EE0 100%);
  color: #FFFFFF;
  padding: 6px 24px;
  border-radius:6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}
.card-cooperative-left-btn-left{
  background: linear-gradient(90deg, #6C5CF7 0%, #563EE0 100%);
  color: #FFFFFF;
  padding: 6px 24px;
  border-radius:6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.card-cooperative-right-img{
  position: absolute;
  top: 40px;
}
.card-cooperative-left-btn{
  gap: 16px;
}
.card-cooperative-right{
  justify-content: left;
}
.card-cooperative-left-btn-left{

}

.card-cooperative{
  border-radius: 16px
}

.card-cooperative-left{
  padding-left: 40px;
}

.card-cooperative-left-title{
  color: #212529;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.card-cooperative-left-description{
  color: #52526CBF;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.card-cooperative{
  width: 100%;
  background-color: #ffffff;
}

.wrapper-mobile-img-tel{
  z-index: 1;
  top: 97px;
  right: 0;
  position: absolute;
}

.wrapper-mobile-img-telDarkBot{
  z-index: 1;
  top: 62px;
  right: 20px;
  position: absolute;
}
.wrapper-mobile-img-telBot{
  z-index: 1;
  top: 145px;
  right: 0;
  position: absolute;
}
.wrapper-mobile-inner{
  overflow: hidden;
  border-radius: 16px;
  background-color: #7366FF;
}
.wrapper-bot-inner{
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  background-color: #2F2F3B;
}

.wrapper-mobile-left-btn{
  margin-bottom: 61px;
  margin-top: 24px;
}
@media screen and (max-width: 1676px){
  .wrapper-mobile-img-tel{
    top: 117px;
  }
  .wrapper-mobile-img-telDark{
    top: 90px;
  }
  .wrapper-mobile-img-telDarkBot{
    top: 90px;
  }
  .wrapper-mobile-left-description{
    max-width: 350px !important;
  }
}
@media screen and (max-width: 1598px){
  .wrapper-mobile-img-tel{
    top: 148px;
    right: -40px;
  }
  .wrapper-mobile-img-telDarkBot{
    top: 127px;
    right: 10px;
  }
  .wrapper-mobile-left-title{
    max-width: 310px !important;
  }
  .wrapper-mobile-img-telBot{
    top: 178px;
    right: -60px;
  }
  .wrapper-mobile-img-telDark{
    top: 117px;
  }
  .wrapper-mobile-left-description{
    max-width: 350px !important;
  }
}
@media screen and (max-width: 1472px){
  .wrapper-mobile-img-tel{
    top: 158px;
    right: -40px;
  }
  .wrapper-mobile-img-telBot{
    top: 178px;
    right: -75px;
  }
  .wrapper-mobile-img-telDark{
    top: 130px;
  }
  .wrapper-mobile-left-description{
    max-width: 350px !important;
  }
}
@media screen and (max-width: 1360px){
  .card-cooperative{
    flex-direction: column;
  }
}
@media screen and (max-width: 1399px){
  .wrapper-mobile-img-tel{
    top: 276px;
    right: -10px;
  }
  .wrapper-mobile-img-telBot{
    top: 220px;
    right: -115px;
  }
  .wrapper-mobile-img-telDark{
    top: 250px;
  }
  .wrapper-mobile{
    height: 565px;
  }
  .wrapper-mobile-left-description{
    max-width: 250px !important;
  }
  .wrapper-bot{
    height: 440px;
  }
}
@media screen and (max-width: 685px){
  .card-cooperative-left-btn{
    flex-direction: column;
  }
  .card-cooperative-left{
    padding-left: 10px;
    padding-right: 10px;
  }
  .card-cooperative-right-img{
    left: 80px;
    top: 110px;
    width: 421px;
    height: 267px;
  }
}
@media screen and (max-width: 577px){
  .card-cooperative-right-img{
    left: 40px;
    top: 110px;
    width: 421px;
    height: 267px;
  }
}
@media screen and (max-width: 492px){
  .wrapper-mobile-img-tel{
    top: 280px;
    right: 0px;
  }
  .card-cooperative-right-img{
    left: -40px;
    top: 135px;
    width: 421px;
    height: 267px;
  }
  .wrapper-mobile-img-telDark{
    top: 250px;
  }

  .wrapper-mobile-left-btn img, .wrapper-mobile-left-btn{
    margin: 0 auto;
  }
  .wrapper-mobile-left{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .wrapper-mobile-left-title{
    margin: 0 auto;
    font-size: 17px;
    margin-top: 20px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }
  .wrapper-mobile-left-description{
    font-size: 14px;
    margin: 0 auto;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }
  .wrapper-mobile{
    height: 568px;
  }
  .wrapper-bot{
    height: 605px;
  }
  .wrapper-mobile-img-telBot{
    top: 335px;
    right: -84px;
  }
  .wrapper-mobile-img-telDarkBot{
    top: 285px;
  }
}
@keyframes slide-enter-right {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-enter-top {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1 !important;
    transform: translateY(0);
  }
}

@keyframes slide-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.wrapper-parents-android{
  display: none !important;
}
.wrapper-parents{
  display: block;
}
.account-name{
  font-size: 20px;
  font-weight: 500;
}
.ttl-info{
  color: #59667a;
}
.ttl-info h6{
  font-size: 12px;
}
.avatar-text {
  font-size: 24px;
}
.title-account {
  border-bottom: 1px solid #dee2e6;
}
.avatar {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: #449df3;
}
.wrapper-btn-video {
  display: flex;
  justify-content: space-around;
}
.wrapper-profile {
  max-width: 1400px;
  margin: 30px auto;
}
.card {
  overflow: hidden;
  position: relative;
}
.img {
  display: block;
  position: absolute;
  right: -50px;
  z-index: 1;
}
.wrapper-btn {
  margin-left: 28px;
}
.card-body,
.wrapper-btn {
  z-index: 2;
}
.img-project-img {
  border-radius: 5px;
  margin: 0 auto;
}
.card{
  position: relative;
}
.popover {
  position: absolute;
  top: 15px;
  right: 50px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 999;
  opacity: 1;
  transition: all .5s;
}
@media (max-width: 600px) {
  .wrapper-parents{
    display: none !important;
  }
  .wrapper-parents-android{
    display: block !important;
  }
}
@media (max-width: 1200px) {
  .img {
    display: none;
  }
}

</style>
