<template>
<div class="wrapper-shareProject">
  <div class="ShareProject" @click="shareProject()">
    <img src="@/assets/images/pages/project/detail/Share.svg" alt="">
    Поделиться проектом
  </div>
</div>
</template>

<script>
export default {
  name: "shareProject",
  methods: {
    shareProject() {
      const currentUrl = window.location.href;
      navigator.clipboard.writeText(currentUrl)
          .then(() => {
            console.log('Ссылка скопирована в буфер обмена:', currentUrl);
            alert('Ссылка скопирована в буфер обмена!');
          })
          .catch(err => {
            console.error('Ошибка при копировании ссылки:', err);
            alert('Не удалось скопировать ссылку.');
          });
    },
  }
}
</script>
