<template>
  <div class="wrapper-become">
    <Breadcrumbs title="СТАТЬ ПАРТНЁРОМ" main="УСЛУГИ"/>

    <div class="card d-flex flex-column-reverse flex-md-row justify-content-between align-items-stretch py-4 px-3 p-md-5">
      <div class="w-100 w-md-50">
        <h5 class="mb-3 d-none d-md-block" >
          Стать партнёром
        </h5>
        <div class="description">
          <p class="mb-1 mt-2">
            Описание товара или услуги
            <span class="text-danger">*</span>
          </p>
          <textarea class="form-control" placeholder="Напишите описание" v-model="form.description"></textarea>
        </div>
        <div>
          <label class="form-label mb-1 mt-2">Сайт</label>
          <input
              class="form-control"
              type="text"
              placeholder="Прикрепите ссылку на сайт"
              v-model="form.site"
          />
        </div>
        <div>
          <label class="form-label mb-1 mt-2">
            Номер телефона
            <span class="text-danger">*</span>
          </label>
          <input
              class="form-control"
              v-model="form.phone"
              v-mask="'+0 000 000 0000'"
              type="text"
              placeholder="Ваш телефон"
          />
          <small>Формат +7 000 000 0000</small>
        </div>
        <div>
          <label class="form-label mb-1 mt-2">Город</label>
          <input
              class="form-control"
              v-model="form.city"
              type="text"
              placeholder="Город"
          />
        </div>
        <div class="url-site">
          <p class="mb-1 mt-2">
            Фото
            <span class="text-danger">*</span>
          </p>
          <label class="url-site-label form-control cursor-pointer" for="site-url">
            <img src="@/assets/images/input_file/clip.svg" alt=""/>
            <p class="m-0">
              {{ fileUrl ? fileUrl : 'Прикрепите фото'}}
            </p>
            <input class="url-site-input" type="file" multiple id="site-url" @change="previewFiles" :placeholder="fileUrl">
          </label>
          <small>Разрешенные типы: png, jpg, jpeg. Максимальный размер файла в байтах (2 МБ)</small>
        </div>
        <div>
          <label class="form-label mb-1 mt-2">Ссылка на видео
          </label>
          <input
              class="form-control"
              type="text"
              v-model="form.video"
              placeholder="Прикрепите ссылку на видео"
          />
        </div>
        <div>
          <label class="form-label mb-2 mt-3">Есть ли доставка по России?</label>
          <div class="ms-1 d-flex">
            <div class="radio">
              <input id="radio-1" name="radio" type="radio" :value="true" v-model="form.isDelivery" checked>
              <label for="radio-1" class="radio-labels">Да</label>
            </div>

            <div class="ms-3 radio">
              <input id="radio-2" name="radio" type="radio" :value="false" v-model="form.isDelivery">
              <label  for="radio-2" class="radio-labels">Нет</label>
            </div>
          </div>
          <span v-if="alertFilling" class="text-danger">
            Заполните все обязательные поля
          </span>
          <span v-if="urlCheck" class="text-danger">
            Ссылки(а) - указано неверно. Проверте наличие протокола!
          </span>
        </div>
        <div class="btn btn-outline-primary mt-3" @click="postPartnerForm">
          Отправить
        </div>
      </div>
      <div class="w-100 w-md-50 ps-0 ps-md-5 py-4 d-flex justify-content-center align-items-center">
        <img src="@/assets/images/ITService/become-image.svg" class="img-fluid w-100" alt="">
      </div>
      <h5 class="mb-3 d-block d-md-none" >
        Стать партнёром
      </h5>
    </div>
    <div :class="isActiveModal ? `active` : ``" class="modal-wrapper">
      <div class="modal-overlay" @click="switchModal"></div>
      <div class="modal-content" >
        <div
            v-if="isLoading"
            class="modal-body d-flex flex-column justify-content-center align-items-center mx-4 my-3">
          <div class="loader-cover m-5"></div>
        </div>
        <div
            v-if="errorMessage"
             class="modal-body d-flex flex-column justify-content-center align-items-center mx-4 my-3">
          <img src="@/assets/svg/info-circle.svg" alt="">
          <h4 class="title my-3" style="color: #444444">
            Ошибка!
          </h4>
          <div class="subtitle text-center mb-3" style="color: #52526CBF">
            {{ errorMessage }}
          </div>
          <div class="btn-wrapper text-end w-100">
            <div class="btn btn-primary" @click="switchModal">
              Ок
            </div>
          </div>
        </div>
        <div
            v-if="successMessage"
            class="modal-body d-flex flex-column justify-content-center align-items-center mx-4 my-3">
          <img src="@/assets/svg/Vector.svg" alt="">
          <h4 class="title my-3" style="color: #444444">
            Успешно!
          </h4>
          <div class="subtitle text-center mb-3" style="color: #52526CBF">
            {{ successMessage }}
          </div>
          <div class="btn-wrapper text-end w-100">
            <div class="btn btn-primary" @click="switchModal">
              Ок
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PartnersDataService from "@/services/PartnersDataService";

export default {
  data(){
    return{
      isLoading: false,
      alertFilling: false,
      urlCheck: false,
      successMessage: null,
      errorMessage: null,
      fileUrl: null,
      phone: null,
      isActiveModal: false,
      form: {
        description: null,
        phone: null,
        site: null,
        city: null,
        photo: null,
        video: null,
        isDelivery: false,
      }
    }
  },
  methods: {
    previewFiles(event) {

      const files = event.target.files;

      if (files.length > 0) {
        this.form.photo = files[0];
        this.fileUrl = files[0].name;
      } else {
        this.form.photo = null;
        this.fileUrl = null;
      }

    },
    switchModal(){
      this.isActiveModal = !this.isActiveModal;
    },

    getCorrectedUrl(url) {
      if (url.startsWith('http://') || url.startsWith('https://')) {
        return url;
      } else {

        return false;
      }
    },

    async postPartnerForm(){

      if (this.form.description &&
          this.form.photo &&
          this.form.phone) {

        if (this.form.site) {

          const correctedSite = this.getCorrectedUrl(this.form.site);
          if (!correctedSite) {
            this.urlCheck = true;
            setTimeout(() => {
              this.urlCheck = false;
            }, 3000);
            return;
          }
        }

        if (this.form.video) {

          const correctedVideo = this.getCorrectedUrl(this.form.video);
          if (!correctedVideo) {
            this.urlCheck = true;
            setTimeout(() => {
              this.urlCheck = false;
            }, 3000);

            return;
          }
        }

        this.successMessage = null
        this.errorMessage = null
        this.isLoading = true
        this.switchModal()
          await PartnersDataService.postPartnerForm(this.form)
              .then((response) => {
                this.successMessage = 'Вы успешно отправили заявку!'

                this.form = {
                  description: null,
                  phone: null,
                  site: null,
                  city: null,
                  photo: null,
                  video: null,
                  isDelivery: false,
                };

                this.fileUrl = null

                this.isLoading = false
              })
              .catch((error) => {
                this.isLoading = false
                if (error.response.status === 415) {
                  this.errorMessage = 'Изображение, формат или размер не верны.'
                } else {

                  this.errorMessage = 'Проблема сервера, повторити попытку позже.'
                }
                console.log(error)
              })

      } else {
        this.alertFilling = true
        setTimeout(() => {
          this.alertFilling = false
        }, 3000)
      }
    }

  }
}

</script>