<template>
  <div class="wrapper-modalPayment">
    <div class="card card-left-bottom">
      <div
          class="wrapper-document"
          v-for="item in documents"
          :key="item.path"
          @click="openDocument(item.path)"
      >
      {{ item.rusNameFile }}
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "modalDocuments",
  data(){
    return{
      frontUrl: process.env.VUE_APP_BACKEND_API_BASE,
    }
  },
  props: {
    documents: {
      type: Array,
      required: true
    }
  },
  methods: {
    openDocument(path) {
      window.open(this.frontUrl+ "/" + path, '_blank');
    }
  }
}
</script>

<style scoped>
.wrapper-document {
  cursor: pointer;
}
</style>